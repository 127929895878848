import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, Modal, StyleSheet } from 'react-native';
import { primaryColor } from '../Constant';

const AppModalAlert = ({ showModal = false, text }) => {
    const [modalVisible, setModalVisible] = useState(showModal);

    useEffect(() => {
      setModalVisible(showModal);
    }, [showModal]);
  
    const openModal = () => {
      setModalVisible(true);
    };
  
    const closeModal = () => {
      setModalVisible(false);
    };
  
    return (
      <View style={styles.container}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={closeModal}
        >
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
                {
                    text &&
                    <Text style={styles.modalText}>{text}</Text>
                }
              <TouchableOpacity onPress={closeModal} style={styles.closeButton}>
                <Text style={styles.closeButtonText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>
    );
  };
  
  export default AppModalAlert;
  
  const styles = StyleSheet.create({
    container: {
        position: 'absolute',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      backgroundColor: '#3498db',
      padding: 10,
      borderRadius: 5,
    },
    buttonText: {
      color: 'white',
      fontSize: 16,
      fontWeight: 'bold',
    },
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
      minWidth: '80%',
      backgroundColor: 'white',
      padding: 20,
      borderRadius: 10,
      alignItems: 'center',
    },
    modalText: {
      fontSize: 18,
      marginBottom: 15,
    },
    closeButton: {
      // backgroundColor: primaryColor,
      // padding: 10,
      // borderRadius: 5,
      // marginTop: 10,
      // width: 100,
    },
    closeButtonText: {
      color: primaryColor,
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'center',
    },
  });
  