import React from 'react';
import { Button, View, Share } from 'react-native';
import * as FileSystem from 'expo-file-system';

export default function ExampleExportCSV() {
  const data = [
    ['Name', 'Age', 'Country'],
    ['John', 25, 'USA'],
    ['Jane', 30, 'Canada'],
  ];

  const generateCsvData = () => {
    return data.map(row => row.join(',')).join('\n');
  };

  const saveAndShareCsv = async () => {
    const csvData = generateCsvData();
    const filePath = `${FileSystem.documentDirectory}example.csv`;

    try {
      await FileSystem.writeAsStringAsync(filePath, csvData, {
        encoding: FileSystem.EncodingType.UTF8,
      });

      console.log('filepatch:::> '+ filePath)

      const shared = await Share.share({
        title: 'Share CSV File',
        message: 'Check out this CSV file!',
        url: `file://${filePath}`,
      });

      if (shared.action === Share.sharedAction) {
        if (shared.activityType) {
          console.log('Shared with activity type:', shared.activityType);
        } else {
          console.log('Shared');
        }
      } else if (shared.action === Share.dismissedAction) {
        console.log('Dismissed');
      }
    } catch (error) {
      console.error('Error saving or sharing CSV file:', error);
    }
  };

  return (
    // <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Button title="Export and Share CSV" onPress={saveAndShareCsv} />
    // </View>
  );
}
