import { SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/auth'
import { Button, Divider } from 'react-native-paper';
import { primaryColor, version } from '../Constant';
import LoadingScreen from './LoadingScreen';
import { AuthSession_objectGetData } from '../helper/session/AuthSession';
import Jumbotron from '../components/beranda/Jumbotron';
import MenuItem from '../components/beranda/MenuItem';
import { Searchbar } from 'react-native-paper';

import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
// import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { DataMenu } from '../data/DataMenu';
import { List } from 'react-native-paper';
import AppModalLogout from '../components/AppModalLogout';
import AppModalConfirmation from '../components/AppModalConfirmation';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export default function Profile({
  navigation
}) {
  const { signOut } = useAuth();
  
  const [loading_screen, set_loading_screen] = useState(true);
  const [partnerData, set_partnerData ] = useState('')
  const [filtered_menu, set_filtered_menu] = useState(DataMenu);
  const [visible_modal_logout, set_visible_modal_logout] = useState(false);
  // const [partnerData, set_partnerData ] = useState({
  //   avatar_128: '',
  //   name: '',
  //   email: ''
  // });

  const [searchQuery, setSearchQuery] = React.useState('');

  const onChangeSearch = query => {
    console.log('qeur',query)
    setSearchQuery(query)
  };

  
  useEffect( () => {
    retriveSession();
  }, [])
  
  useEffect( () => {
    const filteredItems = DataMenu.filter(item => 
        // item.name.toLowerCase() === searchQuery.toLowerCase()
        item.name?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
    set_filtered_menu(filteredItems);

  },[searchQuery])

  const retriveSession = async () => {
    set_loading_screen(true);
    const pd = await AuthSession_objectGetData('partnerData');
    set_partnerData(pd);
    set_loading_screen(false)
  }

  const onCancelLogout = () => {
    set_visible_modal_logout(false);
  }

  const handleLogout = () => {
    // signOut();
    set_visible_modal_logout(true);

  }

  if ( loading_screen ) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <>
      
      <AppModalConfirmation
        isVisible={visible_modal_logout}
        message="Apakah kamu yakin ingin keluar?"
        cancel_text="Batal"
        confirm_text="Logout"
        onCancel={onCancelLogout}
        onConfirm={ () => {
          signOut();
        }}
      />
      <SafeAreaView style={{
          flex: 1,
          backgroundColor:'#ffffff'
          // justifyContent: 'center',
          // alignItems: 'center',
      }}>
        <Jumbotron partnerData={partnerData} />
        <ScrollView
          contentContainerStyle={{
            paddingVertical: 15,
          }}
        >
          {/* <List.Item
            onPress={ () => {
              navigation.navigate('About')
            }}
            title="About"
            description="Tentang aplikasi"
            left={props => <FontAwesome5  {...props} name="question" size={24} />}
            right={props => <List.Icon {...props} icon="chevron-right" />}
          />
          <Divider /> */}
          <List.Item
            onPress={() => {
              navigation.navigate('ProfileEdit')
            }}
            title="Ubah Profil"
            description="Ubah profil kamu disini"
            left={props => <MaterialCommunityIcons {...props} name="account-edit" size={24} color="black" />}
            right={props => <List.Icon {...props} icon="chevron-right" />}
          />
          {/* <List.Item
            onPress={() => {
              navigation.navigate('Settings')
            }}
            title="Settings"
            description="Settings "
            left={props => <MaterialCommunityIcons {...props} name="cog" size={24} color="black" />}
            right={props => <List.Icon {...props} icon="chevron-right" />}
          /> */}
          <Divider />
          <List.Item
            onPress={handleLogout}
            title="Logout"
            description="Keluar dari akun anda"
            left={props => <MaterialCommunityIcons {...props} name="logout" size={24} />}
            right={props => <List.Icon {...props} icon="chevron-right" />}
          />
          <Divider />
        </ScrollView>
      
        {/* <Button 
          style={{
            marginHorizontal: 17,
          }}
          buttonColor={primaryColor}
          mode='contained'
          // icon={'door-open'}
          onPress={ () => signOut()}
        >
          Logout
        </Button> */}

        <Text style={{ textAlign: 'center',  fontSize: 12, color: 'gray'}}>Version {version}</Text>
      </SafeAreaView>
    </>
  )
}

const styles = StyleSheet.create({
  menuItemContainer: {
    width: '25%',
    borderWidth: 1,
    alignItems: 'center',
    marginBottom: 15,
  }
})