import axios from "axios";
import moment from "moment/moment";
import 'moment/locale/id'  // without this line it didn't work
moment.locale('id')

export function getDayName(dateStr, locale) {

    var days2 = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date(dateStr.substr(0,10));
    var dayName = days2[d.getDay()];
    return dayName;
}

export   function getLocationName(lat,lon) {
    var config = {
        method: 'get',
        url: 'https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat='+lat+'&lon='+lon+'',
        headers: { }
      };

    axios(config)
    .then(function (response) {
        return response.data.display_name
        // return "cok";
        // var locName = response.data;
    })
    .catch(function (error) {
        console.log(error);
    });
}



export const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = yyyy + '-' + mm + '-' + dd;

    return formattedToday;
} 

export function formatTanggal(x) {
    const dateString = x;
    const formattedDate = moment(dateString).format('YYYY-MM-DD');
    console.log('formattedDate', formattedDate);
    return formattedDate;
}

export const getCurrentDatetime = () => {
    const dateObj = new Date();

    let year = dateObj.getFullYear();

    let month = dateObj.getMonth();
    month = ('0' + (month + 1)).slice(-2);
    // To make sure the month always has 2-character-format. For example, 1 => 01, 2 => 02

    let date = dateObj.getDate();
    date = ('0' + date).slice(-2);
    // To make sure the date always has 2-character-format

    let hour = dateObj.getHours();
    hour = ('0' + hour).slice(-2);
    // To make sure the hour always has 2-character-format

    let minute = dateObj.getMinutes();
    minute = ('0' + minute).slice(-2);
    // To make sure the minute always has 2-character-format

    let second = dateObj.getSeconds();
    second = ('0' + second).slice(-2);
    // To make sure the second always has 2-character-format

    const time = `${year}-${month}-${date} ${hour}:${minute}:${second}`;

    return time;
}

export const getTheDayName = ( tgl ) => {
    // const date_str = "2023-03-09";
    const date_str = tgl;
    const date = new Date(date_str);
    const full_day_name = date.toLocaleDateString('default', { weekday: 'long' });

    const short_day_name = date.toLocaleDateString('default', { weekday: 'short' });

    return short_day_name.substring(0,3);
    
}


export const date_formate = (date_in) => {
    
    // const fmt_date = moment(date_in).format("DD-MM-YYYY HH:mm");
    const fmt_date = moment(date_in).format("dddd, D MMMM YYYY");
    return fmt_date;
}

export const date_formate_absen_hari = (date_in) => {
    
    // const fmt_date = moment(date_in).format("DD-MM-YYYY HH:mm");
    const fmt_date = moment(date_in).format("ddd, MMM YYYY");
    return fmt_date;
}

export const date_formate_absen_jam = (date_in) => {
    
    // const fmt_date = moment(date_in).format("DD-MM-YYYY HH:mm");
    var plus7 = tambah7Jam(date_in);
    const fmt_date = moment(plus7).format("HH:mm:ss");
    return fmt_date;
}

export const date_formate_absen_tanggal = (date_in) => {
    
    // const fmt_date = moment(date_in).format("DD-MM-YYYY HH:mm");
    const fmt_date = moment(date_in).format("D");
    return fmt_date;
}

export const date_human_readble = ( datex) => {
    const date = new Date(datex);

    const fmt_date = moment(date).format("dddd, D MMMM YYYY");

    return fmt_date;
}


export function getTimeStamp(length) {
    var timestamp = Number(new Date()); // current time as number
   
    return timestamp;
}


export function parsedTimeStamp(data) {
    var date = new Date(data);

    var year = date.getFullYear();
    var month = ( parseInt(date.getMonth() + 1) < 10 ? "0"+(date.getMonth() + 1): date.getMonth() + 1);
    var day = ( parseInt(date.getDate() + 1) < 10 ? "0"+(date.getDate() + 1): date.getDate() + 1);
    var hours = ( parseInt(date.getHours() + 1) < 10 ? "0"+(date.getHours() + 1): date.getHours() + 1);
    var minutes =  ( parseInt(date.getMinutes() + 1) < 10 ? "0"+(date.getMinutes() + 1): date.getMinutes() + 1);
    var seconds = ( parseInt(date.getSeconds() + 1) < 10 ? "0"+(date.getSeconds() + 1): date.getSeconds() + 1);

    var result =  year + "-" + month + "-" + day +' '+hours + ":" + minutes + ":" + seconds;
   
    return result;
}

export function parsedPhone(number) {
   var result =  (number.substring(0,1) == '0'? '62'+number.substring(1,15) : number);
   result = result.replaceAll('.','');
   result = result.replaceAll(' ','');
   result = result.replaceAll('-','');
   result = result.replaceAll('+','');
   return result;
}


export function getIndonesiaTime(datex) {
    const newDateTime = moment(datex).add(7, 'hours').format('YYYY-MM-DD HH:mm:ss');
    return newDateTime;
}

// export function sortDataMenu(data) {
//     return data.sort((a, b) => a.name.localeCompare(b.name));
// }

export const sortDataMenu = (data) => {
    const sortedData = data.map((section) => ({
      ...section,
      data: section.data.slice().sort((a, b) => a.name.localeCompare(b.name)),
    }));
    return sortedData;
};
export function tambah7Jam(datex) {
    const newDateTime = moment(datex).add(7, 'hours').format('YYYY-MM-DD HH:mm:ss');
    return newDateTime;
}

export function getMonthsAndFirstDates(year) {
    const months = [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember',
    ];
  
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const results = [];

    for (let i = 0; i < months.length; i++) {
      const month = i;
      const firstDate = new Date((year?year:currentYear), month, 1);
      var firstDateX = moment(firstDate).format('YYYY-MM-DD')
      results.push({
        label: months[i],
        // firstDate: firstDate.toDateString(),
        value: firstDateX,
      });
    }
  
    return results;
  }
    

 export function numberWithDot(number) {
    // Memastikan input adalah angka
    if (typeof number !== 'number') {
      return "Invalid input";
    }
  
    // Mengubah angka menjadi format mata uang Rupiah
    const formattedNumber =  new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number);

    return formattedNumber.replace(/\,00$/, '');
  }
  
  export function formatFileSize(bytes) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let index = 0;
  
    while (bytes >= 1024 && index < 8) {
      bytes /= 1024;
      index++;
    }
  
    // Round to two decimal places using * and / operations
    const roundedSize = Math.round(bytes * 100) / 100;
    return roundedSize.toFixed(2) + ' ' + units[index];
  }

export function convertToBytes(value, unit) {
    const unitMap = {
      'B': 1,
      'KB': 1024,
      'MB': 1024 * 1024,
      'GB': 1024 * 1024 * 1024,
      'TB': 1024 * 1024 * 1024 * 1024,
      'PB': 1024 * 1024 * 1024 * 1024 * 1024,
      'EB': 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
      'ZB': 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
      'YB': 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024
    };
  
    unit = unit.toUpperCase();
  
    if (unitMap[unit] === undefined) {
      console.log('Unit not recognized. Supported units are: B, KB, MB, GB, TB, PB, EB, ZB, YB');
      return;
    }
  
    if (isNaN(value) || value <= 0) {
      console.log('Invalid value. Please provide a positive number.');
      return;
    }
  
    return value * unitMap[unit];
  }