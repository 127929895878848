import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { primaryColor } from '../../Constant';

export default function MenuItem({
    name, onPress,
    icon, 
}) {
  return (
    <TouchableOpacity
        style={ styles.menuItemContainer }
        onPress={onPress}
    >
    <View style={{ backgroundColor: 'white', width: 50, height: 50, borderRadius: 60, alignItems: 'center', justifyContent: 'center'}}>
        {
            icon
        }
    
    </View>
    <Text style={{ fontWeight: '500'}}>{name}</Text>
</TouchableOpacity>
  )
}

const styles = StyleSheet.create({
    menuItemContainer: {
      width: '25%',
    //   borderWidth: 1,
      alignItems: 'center',
      marginBottom: 15,
    }
  })