import {API_MID, } from "@env"
import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import DropDownPicker from 'react-native-dropdown-picker';
import axios from "axios";
import { ApiGetTimeOffType } from "../../api/TimeOffApi";

export default function TimeOffTypeList({
    onSelect,
    value
}) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const [openType, setOpenType] = useState(false);
    const [valueType, setValueType] = useState(value);
    const [itemsType, setItemsType] = useState([
    ]);

    useEffect( () => {
        getApiTimeOffType();
    }, [])

    const getApiTimeOffType = async () => {
        const res = await ApiGetTimeOffType();

        if ( res.code == '200') {
            setItemsType(res.data)
        } else {
            alert(res.message)
        }

    }
    
  return (
        <DropDownPicker
            placeholder='Time off type'
            listMode='MODAL'
            schema={{
                label: 'display_name', // required
                value: 'id', // required
            }}
            open={openType}
            value={valueType}
            items={itemsType}
            setOpen={setOpenType}
            setValue={setValueType}
            setItems={setItemsType}
            onSelectItem={ (item) => {
                // set_month_name(item.label);
                onSelect(item);
            }}
        />
  )
}

const styles = StyleSheet.create({})