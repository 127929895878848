import React from 'react';
import { Dimensions } from 'react-native';
import { View } from 'react-native';
import WebView from 'react-native-webview';
import RenderHtml from 'react-native-render-html';

const MyHTMLComponent = ({content}) => {
  const { width } = Dimensions.get('window');

  var widthPx = parseInt(width);
  widthPx = widthPx+'px'

  const source = {
    html: `${content}`
  };

  
  // console.log('content', contentcontent)
  const htmlContent = `
    <!doctype html>
    <html lang="en">
      <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <title>Bootstrap demo</title>
        
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
        <style>
          // body {
          //   font-size:14px;
          //   padding-right: 15px,
          // }
        </style>
      
      </head>
      <body>
          <div class="container">
            <div class="row">
            <div class="col">
              ${content}
              </div>
            </div>
          </div>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4" crossorigin="anonymous"></script>
      </body>
    </html>
  `;

  return (
    <RenderHtml
      contentWidth={width}
      source={source}
      baseStyle={{
        paddingHorizontal: 15,
      }}
    />
  )
  // OLD
  return (
    <WebView
      style={{
        flex: 1,
      }}
      originWhitelist={['*']}
      source={{ html: htmlContent }}
    />
  );
};

export default MyHTMLComponent;
