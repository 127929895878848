import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import { primaryColor } from '../../Constant'
import { FontAwesome } from '@expo/vector-icons';
import { ScrollView } from 'react-native';
import TimeOffList from '../../components/time-off/TimeOfList';

export default function TimeOff({ route, navigation }) {
  return (
    <SafeAreaView
        style={
            {
                flex: 1,
                // backgroundColor: 'white',
            }
        }
    >
      
        <TimeOffList />
        <View
        style={
            {
                position: 'absolute',
                bottom: 35,
                right: 15,
            }
        }
      >
        <TouchableOpacity
            style={
                {
                    backgroundColor: primaryColor,
                    borderRadius: 50,
                    height: 55,
                    width: 55,
                    justifyContent: 'center',
                    alignItems: 'center',
                }
            }

            onPress={ () => {
                navigation.push('TimeOffAdd')
                // alert('test')
            }}
        >
            <FontAwesome name="plus" size={24} color="white" />
        </TouchableOpacity>
        
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})