import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import React, { useState } from 'react'
import LoadingScreen from '../LoadingScreen';
import { ApiCreateTicket } from '../../api/TicketsApi';
import { primaryColor } from '../../Constant';

export default function TicketCreate({
    navigation,
}) {

    const [ loading_screen, set_loading_screen] = useState(false);
  const [formData, setFormData] = useState({
    subject: '',
    description: '',
  });

  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

    const handlSubmit = async () => {
        set_loading_screen(true);
        const res = await ApiCreateTicket(formData);
        set_loading_screen(false);

        if ( res.data.code == '200' ) {
            alert("Berhasil membuat tiket");
            handleChange('subject', '');
            handleChange('description', '');
        } else if ( res.data.code == '400' ) {
            console.log("res.data.errors.includes('subject')", res.data.errors)
            if ( res.data.errors.subject ) {
                alert('Subject tidak boleh kosong');
                return false;
            }

            if ( res.data.errors.description ) {
                alert('Description tidak boleh kosong')
                return false;
            }
        } else {
            alert('Terjadi Kesalahan pada server');
        }
    }

  if ( loading_screen ) {
    return (
        <LoadingScreen />
    )
  }

  return (
    <View 
        style={{
            padding: 15
        }}
    >
        <View style={{ marginBottom: 10}}>
            <Text style={{ fontWeight: '300', fontSize: 14, }}>Subject</Text>
            <TextInput 
            placeholder='Subject'
            style={{ borderWidth: 0.2, borderColor: 'gray', paddingVertical: 8, paddingHorizontal: 12}}
            value={formData.subject}
            onChangeText={(value) => handleChange('subject', value)}
            />
        </View>

        <View style={{ marginBottom: 10}}>
            <Text style={{ fontWeight: '300', fontSize: 14, }}>Description</Text>
            <TextInput 
            placeholder='Description'
            style={{ borderWidth: 0.2, borderColor: 'gray', paddingVertical: 8, paddingHorizontal: 12}}
            value={formData.description}
            onChangeText={(value) => handleChange('description', value)}
            />
        </View>

        <View style={{ marginBottom: 10}}>
            <TouchableOpacity
                style={{ 
                    backgroundColor: primaryColor,
                    paddingHorizontal: 12,
                    paddingVertical: 8,
                    marginHorizontal: 15,
                    borderRadius: 8,
                    marginBottom: 10
                }}
                
                onPress={ () => {
                    handlSubmit();
                }}
            > 
                <Text style={{ textAlign: 'center', color: 'white'}}>Create Ticket</Text>
            </TouchableOpacity>
        </View>
    </View>
  )
}

const styles = StyleSheet.create({})