import { StyleSheet, Text, TouchableOpacity, View, Share } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native';
import { ScrollView } from 'react-native';
import RenderHTML from 'react-native-render-html';
import { useWindowDimensions } from 'react-native';
import { date_human_readble } from '../../helper/GlobalFunction';
import * as Sharing from 'expo-sharing';
import { TouchableOpacityBase } from 'react-native';
import { Alert } from 'react-native';
import { primaryColor } from '../../Constant';

export default function AnnouncementDetailScreen({
  route, navigation
}) {
  const { width } = useWindowDimensions();

  const { item } = route.params;
  const [all_text, set_all_text] = useState('');


  const source = {
    html: `${item.announcement}`
  };

  
  const shareText = async () => {
    try {
      const result = await Share.share({
        title: item.announcement_reas,
        message: `${all_text}
        `,
        url: ''
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      Alert.alert(error.message);
    }
}

  useEffect( () => {
    const cleanText = item.announcement.replace(/<[^>]+>/g, '');
    set_all_text(`${item.announcement_reason} \n\n${item.company_id[1]}\n${date_human_readble(item.create_date)}
    ${cleanText}
    `)
  }, [])

  

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: 'white',
      }}
    >
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 50,
          paddingHorizontal: 15,
          paddingTop: 15,
        }}
      >
        <Text style={{ fontSize: 18, fontWeight: '700'}}>{item.announcement_reason}</Text>
        <Text style={{ fontSize: 14, fontWeight: '500', marginTop: 10,}}>- { item.company_id[1] }</Text>
        <Text style={{ fontSize: 12, fontWeight: '300', marginTop: 5,}}>{ date_human_readble(item.create_date)}</Text>
        <RenderHTML
          contentWidth={width}
          source={source}
        />


        <View 
          style={{ borderBottomWidth: 1, borderBottomColor: 'gainsboro', marginBottom: 15,}}
        />

        <TouchableOpacity
          style={{
            backgroundColor: primaryColor,
            width: 100,
            alignSelf: 'center',
            borderRadius: 50,
            paddingHorizontal: 15,
            paddingVertical: 10,
          }}
          onPress={shareText}
        >
          <Text style={{ textAlign: 'center', color: 'white'}}>Bagikan</Text>
          
        </TouchableOpacity>

      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})