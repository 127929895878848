import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { primaryColor } from '../Constant';
import { sortDataMenu } from '../helper/GlobalFunction';

export const Data = [
    {
        title: 'General',
        data: [
            {
                id: 1,
                name: 'CRM',
                navigate: 'CRM',
                icon: <MaterialCommunityIcons name="home-account" color={primaryColor} size={40} />,
            },
            {
                id: 2,
                name: 'Discuss',
                navigate: 'Discuss',
                icon: <FontAwesome name="wechat" color={primaryColor} size={30} />,
            },
            {
                id: 3,
                name: 'Desk',
                navigate: 'Desk',
                icon: <MaterialCommunityIcons name="desk" color={primaryColor} size={30} />,
            },
            {
                id: 4,
                name: 'Employees',
                navigate: 'Employees',
                icon: <FontAwesome5 name="users" color={primaryColor} size={30} />,
            },
            {
                id: 5,
                name: 'Payroll',
                navigate: 'Payroll',
                icon: <FontAwesome name="dollar" color={primaryColor} size={30} />,
            },
            {
                id: 6,
                name: 'Task',
                navigate: 'Task',
                icon: <FontAwesome5 name="tasks" color={primaryColor} size={30} />,
            },
            {
                id: 7,
                name: 'Tickets',
                navigate: 'TicketDashboard',
                icon: <FontAwesome5 name="sticky-note" color={primaryColor} size={30} />,
            },
            {
                id: 8,
                name: 'FSM',
                navigate: 'FSM',
                icon: <FontAwesome5 name="truck" color={primaryColor} size={30} />,
            },
            {
                id: 9,
                name: 'Warehouse',
                navigate: 'Warehouse',
                icon: <FontAwesome5 name="warehouse" color={primaryColor} size={30} />,
            },
            {
                id: 10,
                name: 'Purchasing',
                navigate: 'Purchasing',
                icon: <FontAwesome5 name="cart-plus" color={primaryColor} size={30} />,
            },
            {
                id: 11,
                name: 'Services',
                navigate: 'Services',
                icon: <FontAwesome5 name="hands-helping" color={primaryColor} size={30} />,
            },
            {
                id: 11,
                name: 'Whatsapp',
                navigate: 'Whatsapp',
                icon: <FontAwesome5 name="whatsapp" color={primaryColor} size={30} />,
            },
            {
                id: 12,
                name: 'Project',
                navigate: 'Project',
                icon: <FontAwesome5 name="project-diagram" color={primaryColor} size={30} />,
            },
            {
                id: 13,
                name: 'Time Off',
                navigate: 'TimeOff',
                icon: <FontAwesome5 name="clock" color={primaryColor} size={30} />,
            },
            // {
            //     id: 14,
            //     name: 'Storage',
            //     navigate: 'Storage',
            //     icon: <MaterialCommunityIcons name="all-inclusive-box" size={30} color={primaryColor} />,
            // },
            
        ],
    },
    {
        title: 'Advance',
        data: [
            {
                id: 1,
                name: 'Announce',
                navigate: 'Announcement',
                icon: <FontAwesome5 name="bullhorn" color={primaryColor} size={25} />,
            },
        ]
    }
]

export const DataMenu = sortDataMenu(Data);