import { SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/auth'
import { Button } from 'react-native-paper';
import { primaryColor } from '../Constant';
import LoadingScreen from './LoadingScreen';
import { AuthSession_objectGetData, AuthSession_stringGetData } from '../helper/session/AuthSession';
import Jumbotron from '../components/beranda/Jumbotron';
import MenuItem from '../components/beranda/MenuItem';
import { Searchbar } from 'react-native-paper';

import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { DataMenu } from '../data/DataMenu';
import { SectionList, FlatList } from 'react-native';

export default function Beranda({
  navigation
}) {
  const { signOut } = useAuth();
  
  const [loading_screen, set_loading_screen] = useState(true);
  const [partnerData, set_partnerData ] = useState('')
  const [token, set_token ] = useState('')
  const [filtered_menu, set_filtered_menu] = useState(DataMenu);
  // const [partnerData, set_partnerData ] = useState({
  //   avatar_128: '',
  //   name: '',
  //   email: ''
  // });

  const [searchQuery, setSearchQuery] = React.useState('');

  const onChangeSearch = query => {
    setSearchQuery(query)
  };

  
  useEffect( () => {
    retriveSession();
  }, [])
  
  useEffect( () => {
    // const filteredItems = DataMenu.filter(item => 
    //     // item.name.toLowerCase() === searchQuery.toLowerCase()
    //     item.name.toLowerCase().includes(searchQuery.toLowerCase())
    // );
    // set_filtered_menu(filteredItems);

    const filterDataByName = (name) => {
      const filteredData = DataMenu.map((section) => ({
        ...section,
        data: section.data.filter((item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        ),
      })).filter((section) => section.data.length > 0);
    
      set_filtered_menu(filteredData);

    };

    filterDataByName();

  },[searchQuery])

  const retriveSession = async () => {
    set_loading_screen(true);
    const pd = await AuthSession_objectGetData('partnerData');
    const token = await AuthSession_stringGetData('token');
    // console.log('token',token)
    // console.log('pd', pd[0])
    set_partnerData(pd);
    set_loading_screen(false)
  }

  if ( loading_screen ) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <SafeAreaView style={{
        flex: 1,
        // backgroundColor:'white'
        // justifyContent: 'center',
        // alignItems: 'center',
    }}>
      <Jumbotron partnerData={partnerData} />

      <View style={{ marginHorizontal: 17, marginTop: 15}}>
        <Searchbar
          placeholder="Search"
          onChangeText={onChangeSearch}
          value={searchQuery}
        />
      </View>

      <ScrollView>
        <View style={styles.sectionListContainer}>
                  {filtered_menu.map(section => (
                  <View key={section.title} style={styles.sectionContainer}>
                      <Text style={styles.sectionTitle}>{section.title}</Text>
                      <View
                      style={styles.itemsContainer}
                      >
                          {section.data.map((v,i) => {
                              return(
                                <MenuItem 
                                  key={i}
                                  name={v.name}
                                  icon={
                                    v.icon
                                  }
                              
                                  onPress={ () => {
                                    navigation.navigate(v.navigate)
                                  }}
                                />
                              )
                          })}
                      </View>
                  </View>
                  ))}
          </View>
      </ScrollView>
      


    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  menuItemContainer: {
    width: '25%',
    borderWidth: 1,
    alignItems: 'center',
    marginBottom: 15,
  },
  header: {
    fontSize: 32,
    backgroundColor: '#fff',
    width: '100%'
  },
  sectionListContainer: {
    flex: 1,
    paddingHorizontal: 17,
  },
  sectionContainer: {
    marginBottom: 16,
  },
 
  sectionTitle: {
    fontSize: 14,
    fontWeight: '600',
    marginBottom: 8,
  },
  itemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '29%',
  },
})