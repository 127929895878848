import { SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { ApiGetTicketList } from '../../api/TicketsApi'
import LoadingScreen from '../LoadingScreen'
import { ImageStatic, dangerColor, errorServer, primaryColor, successColor, warningColor } from '../../Constant';
import { Dimensions } from 'react-native';
import { Image } from 'react-native';

const Card = () => {
    return (
        <TouchableOpacity style={{  marginHorizontal: 15, marginBottom: 15, borderWidth: 0.2, borderColor: 'gray', borderRadius:8, backgroundColor: 'white',}}
                    
            onPress={ () => {
                navigation.navigate('')
            } }
        >
            <View
                style={{
                    backgroundColor: 'red',
                    paddingVertical: 15,
                    overflow: 'hidden',
                    borderTopEndRadius: 8,
                    borderTopStartRadius: 8,
                }}
            >
                <Text style={{ textAlign: 'center', color: 'white', fontWeight: 'bold', fontSize: 16,}}>Done</Text>
            </View>
            <View
                style={{
                    padding: 15
                }}
            >

                <View style={{ marginBottom: 10}}>
                    <Text style={{ fontWeight: '300', fontSize: 14,}}>Number</Text>
                    <Text style={{ fontWeight: '600', fontSize: 14}}>HT017793</Text>
                </View>
                <View style={{ marginBottom: 10}}>
                    <Text style={{ fontWeight: '300', fontSize: 14,}}>Name</Text>
                    <Text style={{ fontWeight: '600', fontSize: 14}}>HT017793 - Client NHM-003389 BAMBANG PRASODJO (Complaint)</Text>
                </View>
                <View style={{ marginBottom: 10}}>
                    <Text style={{ fontWeight: '300', fontSize: 14,}}>Team</Text>
                    <Text style={{ fontWeight: '600', fontSize: 14}}>Nethome || Support</Text>
                </View>
                <View style={{ marginBottom: 10}}>
                    <Text style={{ fontWeight: '300', fontSize: 14,}}>Created Date</Text>
                    <Text style={{ fontWeight: '600', fontSize: 14}}>2023-06-14 13:13:33</Text>
                </View>
            </View>
        </TouchableOpacity>
    )
}

export default function TicketList({
    navigation,
}) {
    const {width: windowWidth, height: windowHeight} = Dimensions.get('window');
    const [loading_screen, set_loading_screen] = useState(false);
    const [data_tickets, set_data_tickets] = useState([]);

    useEffect(() => {
        getTickets();
    }, [])

    const getTickets = async () => {
        set_loading_screen(true);
        const res = await ApiGetTicketList();
        set_loading_screen(false);

        console.log('debug  getTickets',res.data.data);
        if ( res.data.code == 200 ) {
            set_data_tickets(res.data.data);
        } else if ( res.data.code == 401 ) {
            alert('Invalid Token. Silahkan login ulang.');
        } else if ( res.data.code == 400 ) {
            alert('Terjadi Kesalahan Saat Request');
        } else {
            alert(errorServer);
        }
        
    }

    if ( loading_screen ) {
        return (
            <LoadingScreen />
        )
    }
  return (
    <SafeAreaView style={
        {
            // backgroundColor: bg_c
            paddingTop: 15
        }
    }>
                
        <TouchableOpacity 
            style={{ 
                backgroundColor: primaryColor,
                paddingHorizontal: 12,
                paddingVertical: 8,
                marginHorizontal: 15,
                borderRadius: 8,
                marginBottom: 10
            }}
            
            onPress={ () => {
                navigation.navigate('TicketCreate');
            }}
        > 
            <Text style={{ textAlign: 'center', color: 'white'}}>Create</Text>
        </TouchableOpacity>
        <ScrollView
            contentContainerStyle={{
                paddingBottom: 50,
                marginBottom: 50,
            }}
        >
            {
                data_tickets.length > 0 ?
                data_tickets.map( (v,i) => {
                    const bg_color = (v.stage_id[1] == 'Done' ? 
                        successColor: (
                            v.stage_id[1] == 'In Progress' ? warningColor : (
                                v.stage_id[1] == 'Cancelled' ? 'gray' : dangerColor
                            )
                        )
                    
                    );
                    const text_color = (v.stage_id[1] == 'Done' ? 'white': 'white');
                    return (
                        <TouchableOpacity 
                            key={i}
                            style={{ 
                                marginHorizontal: 15, marginBottom: 15, borderWidth: 0.2, borderColor: 'gray', borderRadius:8, backgroundColor: 'white',
                                elevation: 7,
                            }}
                    
                            onPress={ () => {
                                navigation.navigate('')
                            } }
                        >
                            <View
                                style={{
                                    backgroundColor: bg_color,
                                    paddingVertical: 15,
                                    overflow: 'hidden',
                                    borderTopEndRadius: 8,
                                    borderTopStartRadius: 8,
                                }}
                            >
                                <Text style={{ textAlign: 'center', color: text_color, fontWeight: 'bold', fontSize: 16,}}>{v.stage_id[1]}</Text>
                            </View>
                            <View
                                style={{
                                    padding: 15
                                }}
                            >

                                <View style={{ marginBottom: 10}}>
                                    <Text style={{ fontWeight: '300', fontSize: 14,}}>Number</Text>
                                    <Text style={{ fontWeight: '600', fontSize: 14}}>{v.number}</Text>
                                </View>
                                <View style={{ marginBottom: 10}}>
                                    <Text style={{ fontWeight: '300', fontSize: 14,}}>Name</Text>
                                    <Text style={{ fontWeight: '600', fontSize: 14}}>{v.name}</Text>
                                </View>
                                <View style={{ marginBottom: 10}}>
                                    <Text style={{ fontWeight: '300', fontSize: 14,}}>Team</Text>
                                    <Text style={{ fontWeight: '600', fontSize: 14}}>{v.team_id[1]}</Text>
                                </View>
                                <View style={{ marginBottom: 10}}>
                                    <Text style={{ fontWeight: '300', fontSize: 14,}}>Created Date</Text>
                                    <Text style={{ fontWeight: '600', fontSize: 14}}>{v.create_date}</Text>
                                </View>
                            </View>
                        </TouchableOpacity>

                        // <TouchableOpacity 
                        //     key={i}
                        //     style={{ marginHorizontal: 15, marginBottom: 15, borderWidth: 0.2, borderColor: 'gray', borderRadius:8, backgroundColor: bg_color, padding: 15}}
                        
                        //     onPress={ () => {
                        //         navigation.navigate('');
                        //     } }
                        // >
                        //     <View style={{ marginBottom: 10}}>
                        //         <Text style={{ fontWeight: '300', fontSize: 14, color: text_color}}>Number</Text>
                        //         <Text style={{ fontWeight: '600', fontSize: 14, color: text_color}}>{v.number}</Text>
                        //     </View>
                        //     <View style={{ marginBottom: 10}}>
                        //         <Text style={{ fontWeight: '300', fontSize: 14, color: text_color}}>Name</Text>
                        //         <Text style={{ fontWeight: '600', fontSize: 14, color: text_color}}>{v.name}</Text>
                        //     </View>
                        //     <View style={{ marginBottom: 10}}>
                        //         <Text style={{ fontWeight: '300', fontSize: 14, color: text_color}}>Team</Text>
                        //         <Text style={{ fontWeight: '600', fontSize: 14, color: text_color}}>{v.team_id[1]}</Text>
                        //     </View>
                        //     <View style={{ marginBottom: 10}}>
                        //         <Text style={{ fontWeight: '300', fontSize: 14, color: text_color}}>Status</Text>
                        //         <Text style={{ fontWeight: '600', fontSize: 14, color: text_color}}>{v.stage_id[1]}</Text>
                        //     </View>
                        //     <View style={{ marginBottom: 10}}>
                        //         <Text style={{ fontWeight: '300', fontSize: 14, color: text_color}}>Created Date</Text>
                        //         <Text style={{ fontWeight: '600', fontSize: 14, color: text_color}}>{ getIndonesiaTime(v.create_date) }</Text>
                        //     </View>
                        // </TouchableOpacity>
                    )
                })

                :
                (
                    <View style={{
                        height: windowHeight-200,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Image 
                            source={ ImageStatic.data_not_found}
                            style={{
                                height: windowHeight ,
                                width: windowWidth ,
                                resizeMode: 'contain',
                            }}
                        />
                    </View>
                )
            }
        </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})