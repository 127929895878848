import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { ApiTaskDetail } from '../../api/TaskApi';
import MyHTMLComponent from '../../components/MyHTMLCompnent';
import LoadingScreen from '../LoadingScreen';

export default function TaskDetai({ route, navigation}) {

    const { id, name } = route.params;
    const [loading_screen, set_loading_screen] = useState(false);
    const [form, set_form] = useState({
      description: ''
    })

    useEffect( () => {
        navigation.setOptions({
            headerTitle: name
        })
        getApiTaskDetail();
    }, [])

    const handleInputChange = async (name, value) => {
        set_form((prevFormData) => ({
          ...prevFormData,
          [name]: value
        }));
    
    };

    const getApiTaskDetail = async () => {
        set_loading_screen(true)
        const res = await ApiTaskDetail({
            id: id,
        })
        set_loading_screen(false)

        console.log('debug getApiTaskDetail', res.data.data[0].description)
        handleInputChange('description',res.data.data[0].description);
    }

    if ( loading_screen ) {
      return (
        <LoadingScreen />
      )
    }
  return (
    <View
      style={{
        flex: 1,
      }}
    >
      {/* <Text>{ name }</Text> */}
      {/* <Text>{form.description}</Text> */}
    
      <MyHTMLComponent content={form.description} />
      
    </View>
  )
}

const styles = StyleSheet.create({})