import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { useWindowDimensions } from 'react-native';
import RenderHtml from 'react-native-render-html';
import { primaryColor } from '../../Constant';
import { Pressable } from 'react-native';
import { date_human_readble } from '../../helper/GlobalFunction';


export default function AnncouncementCard({
    item,
    onPress
}) {
  const { width } = useWindowDimensions();

  const source = {
    html: `${item.announcement}`
  };

    useEffect( () => {
        console.log(' item announcement_reason', item.announcement_reason)
        
    }, [])
  return (
    <Pressable style={styles.card}
      onPress={onPress}
    >
      <Text style={styles.status}></Text>
      {/* <View style={ styles.statusContainer}> */}
      {/* </View> */}
      <View style={styles.codeContainer}>
        <Text style={styles.title} numberOfLines={2}>{item.announcement_reason}</Text>
        <Text style={styles.desc}>{item.company_id[1]}</Text>
        {/* <RenderHtml
          contentWidth={width}
          source={source}
        /> */}
        <Text style={styles.date}>{ date_human_readble(item.create_date)}</Text>
      </View>
    </Pressable>
  )
}


const styles = StyleSheet.create({
    card: {
      backgroundColor: '#fff',
      borderRadius: 10,
      paddingHorizontal: 15,
      paddingTop: 15,
      marginHorizontal: 15,
      flexDirection: 'row',
      justifyContent: 'space-between',
      overflow: 'hidden',
      marginTop: 15,
    },
    statusContainer: {
      backgroundColor: primaryColor,
      height: 50,
      width: 50,
      position: 'absolute',
      left: -15,
      top: -15,
      transform: [{ rotate: '-45deg' }],


    },
    status: {
      transform: [{ rotate: '-40deg' }],
        borderRadius: 8,
        paddingHorizontal: 15,
        backgroundColor: primaryColor,
        textAlign: 'center',
        fontSize: 10,
        fontWeight: 'bold',
        color: 'white',
        position: 'absolute',
        top: 15,
        width: 100,
        paddingTop: 25,
        paddingBottom: 5,
        marginLeft: -35,
        marginTop: -25,

    },
    codeContainer: {
      flex: 1,
      padding: 10,
    //   alignItems: 'center',
    },
    code: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    title: {
      //   textAlign: 'center',
      fontSize: 16,
      fontWeight: '700'
    },
    desc: {
      //   textAlign: 'center',
      fontSize: 14,
      fontWeight: '500',
      marginTop: 10,
    },
    date: {
        fontSize: 12,
        fontWeight: '300',
        marginTop: 5,
    }
  });