import { SafeAreaView, ScrollView, StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import DropDownPicker from 'react-native-dropdown-picker';
import TimeOffTypeList from '../../components/time-off/TimeOffTypeList';
import { Button, TextInput } from 'react-native-paper';
import { primaryColor } from '../../Constant';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { formatTanggal } from '../../helper/GlobalFunction';
import { ApiSaveTimeOff } from '../../api/TimeOffApi';
import LoadingScreen from '../LoadingScreen';

export default function TimeOffAdd({route, navigation}) {
  const [loading_screen, set_loading_screen] = useState(true);
  const [form, set_form] = useState({

  })
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

  // FOR EASY HANDLE INPUT ON REACT 
  const handleInputChange = async (name, value) => {

    set_form((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }));

  };


    useEffect( () => {
      setTimeout(() => {
        set_loading_screen(false);
      }, 1000);
    }, [])
    const handleSelectedTimeOffType = (data) => {
        console.log('data handleSelectedTimeOffType', data)
        handleInputChange('time_off_type_id', data.id);
        handleInputChange('time_off_type_display_name', data.display_name);
    }

    const showDatePicker = (type) => {
      handleInputChange('datepicker', type);
      setDatePickerVisibility(true);
    };
  
    const hideDatePicker = () => {
      setDatePickerVisibility(false);
    };
  
    const handleConfirm = (date) => {
      // console.warn("A date has been picked: ", date);
        const fmt = formatTanggal(date)

        if ( form.datepicker == 'start_date') {
            handleInputChange('start_date', fmt)
        }

        if ( form.datepicker == 'end_date') {
          handleInputChange('end_date', fmt)
      }
    //   set_dob(date);
      hideDatePicker();
    };

    const handleSave = async () => {
      if ( !form.time_off_type_id ) {
        alert("Please select type time off");
        return false;
      }
      set_loading_screen(true);
      const res = await ApiSaveTimeOff(form);
      set_loading_screen(false);
      console.log('handleSave', res)
      if ( res.code == '200' || res.code == '201' ) {
        alert("Created Time Off Succesfully");
        navigation.pop(1)
      } else {
        if ( res.error ) {
          alert(res.error)
        } else {
          alert(res.message)
          
        }
      }
    }

    if ( loading_screen ) {
      return (
        <LoadingScreen />
      )
    }
    
  return (
    <SafeAreaView
      style={{
        flex: 1.
      }}
    >
      <ScrollView>

        <View
          style={{
            margin: 17,
          }}
        >
          <TimeOffTypeList 
            onSelect={handleSelectedTimeOffType}
            value={form.time_off_type_id}
          />

        </View>

        <View
          style={{
            marginHorizontal: 17,
            marginBottom: 17,
          }}
        >
            <Text style={{ marginBottom: 5,}}>Dates</Text>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <TextInput 
                  style={{
                    width: '45%'
                }}
                activeUnderlineColor={primaryColor}
                label="From"
                    onPressIn={ () => {
                      showDatePicker('start_date')
                    }}
                value={form.start_date}
                // onChangeText={( text ) => {
                //   handleInputChange('start_date',text)
                // }}
              />

              <TextInput 
                  style={{
                    width: '45%'
                }}
                activeUnderlineColor={primaryColor}
                label="To"
                    onPressIn={ () => {
                      showDatePicker('end_date')
                    }}
                value={form.end_date}
                // onChangeText={( text ) => {
                //   handleInputChange('start_date',text)
                // }}
              />
              <DateTimePickerModal
                  isVisible={isDatePickerVisible}
                  mode="date"
                  onConfirm={handleConfirm}
                  onCancel={hideDatePicker}
              />

            </View>
        </View>

        <View
          style={{
            marginHorizontal: 17,
            marginBottom: 17,
          }}
        >
            <TextInput 
                activeUnderlineColor={primaryColor}
                numberOfLines={3}
                multiline
                label="Description"
                value={form.description}
                onChangeText={( text ) => {
                  handleInputChange('description',text)
                }}
              />

        </View>
      </ScrollView>

      <View
        style={{
          marginHorizontal: 17,
        }}
      >
              <Button
                  style={{
                      width: '100%',
                      alignSelf: 'center',
                      marginBottom: 50,
                  }} 
                  // icon="camera" 
                  mode="contained" 
                  buttonColor={primaryColor} 
                  onPress={() => {
                      handleSave()
                  }}
              >
                  Save
              </Button>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})