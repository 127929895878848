import React from 'react';
import { View, Button, ActivityIndicator } from 'react-native';
import { Provider, Text } from 'react-native-paper';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { successColor } from '../Constant';
// import XLSX from 'xlsx';

const FileExportAndDownloadScreen = ({data_x, file_name, btn_color}) => {
  const [exporting, setExporting] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);


  // const data = [
  //   ['Name', 'Age', 'Country'],
  //   ['John', 25, 'USA'],
  //   ['Jane', 30, 'Canada'],
  // ];

  const generateCsvData = () => {
  console.log('FileExportAndDownloadScreen data_x',data_x)

    return data_x.map(row => row.join(',')).join('\n');
  };

  const exportCsv = async () => {
    setExporting(true);

    const csvData = generateCsvData();
    const filePath = `${FileSystem.documentDirectory}${file_name}.csv`;

    try {
      await FileSystem.writeAsStringAsync(filePath, csvData, {
        encoding: FileSystem.EncodingType.UTF8,
      });
      console.log('File exported to:', filePath);
    } catch (error) {
      console.error('Error exporting file:', error);
    } finally {
      setExporting(false);
    }
  };

  const downloadCsv = async () => {
    setDownloading(true);

    const filePath = `${FileSystem.documentDirectory}${file_name}.csv`;

    try {
      const uri = `file://${filePath}`;
      await Sharing.shareAsync(uri);
      console.log('File shared:', uri);
    } catch (error) {
      console.error('Error sharing file:', error);
    } finally {
      setDownloading(false);
    }
  };

  const handleExportAndDownload = async () => {
    await exportCsv();
    await downloadCsv();
  };

  return (
    <>
      {/* <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}> */}
        <Button
          color={btn_color}
          title="Download CSV"
          onPress={handleExportAndDownload}
          disabled={exporting || downloading}
        />
        {exporting && <ActivityIndicator style={{ marginTop: 10 }} />}
        {downloading && <ActivityIndicator style={{ marginTop: 10 }} />}
        {(exporting || downloading) ? (
          <Text style={{ marginTop: 10 }}>Processing...</Text>
        ) : (
          <></>
          // <Text style={{ marginTop: 10 }}>Click the button to export and download the CSV file.</Text>
        )}
      {/* </View> */}
    </>
  );
};

export default FileExportAndDownloadScreen;
