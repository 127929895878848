import {API_URL, API_TOKEN, API_URL_MOBILE, API_URL_PORTAL} from "@env"
import axios from "axios";
import { AuthSession_objectGetData, AuthSession_stringGetData } from "../helper/session/AuthSession";


export const ApiUserUpdate = async (params) => {
    const token = await AuthSession_stringGetData('token');

    // const username = await AuthSession_stringGetData('@login');
    // const password = await AuthSession_stringGetData('@passwordx');

    console.log('token', token);
    try {
        const res = await axios({
            method: 'POST',
            url: API_URL_MOBILE+'/users/update',
            headers: {
                'Authorization': 'Bearer '+ token,
            },
            data: {
              name: params.name,
              email: params.email,
              phone: params.phone,
            }
        });

        return res;
    } catch (error) {
        console.log('ApiUserUpdate Error', error)
        console.log('ApiUserUpdate end point ', API_URL_MOBILE+'/users/update')
        return error.response;
        
    }
}



export const ApiUserDetail = async (params) => {
    const token = await AuthSession_stringGetData('token');

    // const username = await AuthSession_stringGetData('@login');
    // const password = await AuthSession_stringGetData('@passwordx');

    console.log('token', token);
    try {
        const res = await axios({
            method: 'GET',
            url: API_URL_MOBILE+'/users/details',
            headers: {
                'Authorization': 'Bearer '+ token,
            },
            data: {
            //   name: params.name,
            //   email: params.email,
            //   phone: params.phone,
            }
        });

        return res;
    } catch (error) {
        console.log('ApiUserDetail Error', error)
        console.log('ApiUserDetail end point ', API_URL_MOBILE+'/users/details')
        return error.response;
        
    }
}
