import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

import { Button } from 'react-native-paper';
import { primaryColor } from '../Constant';


export default function Attendance({ route, navigation}) {
    
  return (
    <View style={{
      padding: 17, 
    }}>
        <Button
            style={{
                width: '50%',
                alignSelf: 'center',
                marginBottom: 10,
            }} 
            icon="camera" 
            mode="contained" 
            buttonColor={primaryColor} 
            onPress={() => {
              navigation.navigate('AbsenCamera')
            }}
        >
          <Text style={{ color: 'white'}}>
             Absen
          </Text> 
        </Button>

        <Button
            style={{
                width: '50%',
                alignSelf: 'center',
                marginBottom: 10,
            }} 
            // icon="camera" 
            mode="contained" 
            buttonColor={primaryColor} 
            onPress={() => {
              navigation.navigate('Report')
            }}
        >
            <Text style={{ color: 'white'}}>
              Report
          </Text> 
        </Button>
    </View>
  )
}

const styles = StyleSheet.create({})