import { StyleSheet, Text, View, Image } from 'react-native'
import React,{useEffect, useState} from 'react'
import { primaryColor } from '../../Constant'
import { AuthSession_stringGetData } from '../../helper/session/AuthSession';
import { useFocusEffect } from '@react-navigation/native';

export default function Jumbotron({partnerData}) {

  const [form, set_form] = useState({
    name:'',
    email:'',
    phone:'',
  })


  useFocusEffect(
    React.useCallback(() => {
      retriveSession();

    }, [])
  );
  
  const handleInputChange = async (name, value) => {
      set_form((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }));

  };

  const retriveSession = async () => {
    const name = await AuthSession_stringGetData('name');
    const email = await AuthSession_stringGetData('email');
    const phone = await AuthSession_stringGetData('phone');

    handleInputChange('name',name);
    handleInputChange('email',email);
    handleInputChange('phone',phone);
  }

  return (
       <View style={{
        backgroundColor: primaryColor,
        borderBottomEndRadius:50,
        borderBottomStartRadius:50,
        paddingBottom: 20,
      }}>
        {/* <View style={{ flexDirection: 'row', }}>
            <Text style={{ color: 'white', fontSize: 16, fontWeight: 'bold', marginLeft: 20, }}><Text style={{ fontSize: 16, fontWeight: 'normal'}}>Selamat malam</Text>, {partnerData.name}</Text>
        </View> */}
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', padding: 17, }}>
          <Image
              style={styles.profileImgHeader}
              source={{
                uri: `data:image/png;base64,${partnerData.avatar_128}`
              }}
          />
          <View>
            <Text style={{ color: '#ffffff', fontSize: 14, fontWeight: 'bold'}}>{form.name}</Text>
            <Text style={{ color: '#ffffff'}}>{form.email}</Text>
          </View>
        </View>
      </View>
  )
}

const styles = StyleSheet.create({
    profileImgHeader: {
        width: 120,
        height: 120,
        borderRadius: 60,
        backgroundColor: 'white',
    },
  })