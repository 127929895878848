import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { primaryColor } from '../../Constant'

export default function StorageFolderItem({
    folder_name,
    folder_items,
    folder_size,
}) {

    const MyIcon = () => {
        return (
            <View>
                <MaterialCommunityIcons name="folder" color={primaryColor} size={30} />
            </View>
        )
    }
  return (
    <View
        style={{
            backgroundColor: 'white',
            paddingHorizontal: 15,
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 15,
            borderBottomWidth: 1,
            borderBottomColor: 'gainsboro',
            columnGap: 7,
        }}
    >
    <MyIcon />
      <View>
        <Text style={{ fontSize: 14, fontWeight: '500'}}>{folder_name}</Text>
        {
            folder_items || folder_size ?
            <Text style={{ fontSize: 12, fontWeight: '300'}}>{folder_items? folder_items + ' items ' : ' '} {folder_size}</Text>
            :
            <></>
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({})