import { Text } from "react-native"
import { Image } from "react-native";
import { StyleSheet } from "react-native";
import { View } from "react-native"
import { date_formate_absen_hari, date_formate_absen_jam, date_human_readble  } from "../../helper/GlobalFunction";

export const ReportItem = (props) => {
    const success =  '#6FCF97';
    const warning = '#F2C94C';
    const danger = '#EB5757';
    const bg_ok = (parseInt(props.late)>0? danger : ( props.jam_masuk && props.jam_pulang  ?success: warning));
    return (
        <>
            <View
                style={
                    {
                        backgroundColor: '#F8F8F8',
                        borderWidth: 1,
                        borderRadius: 6,
                        borderColor: '#F8F8F8',
                        padding: 15,
                        marginHorizontal: 16,
                        marginVertical: 10,
                        elevation: 5,
                    }
                }
            >
                
                <View 
                    style={
                        {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }
                    }
                >

                    <View
                        style={
                            {
                                paddingVertical: 8,
                                paddingHorizontal: 12,
                                alignItems: 'center',
                                width: '33%'
                            }
                        }
                    >
                        <Text style={{ color: '#BDBDBD', fontSize: 16, fontWeight: 400}}>Masuk</Text>
                        <View>
                            {
                                props.ci_image?
                                <Image
                                    style={styles.imageS}
                                    source={{
                                    uri: `data:image/png;base64,${props.ci_image}`
                                    }}
                                />
                                :
                                <View 
                                style={styles.noImage}
                                >
                                <Text style={{ fontSize: 12}}> IMAGE IN </Text>
                                </View>
                            }
                        </View>
                        <Text style={{ color: bg_ok, fontSize: 18, fontWeight: 600}}>{props.jam_masuk?date_formate_absen_jam(props.jam_masuk):'-'}</Text>
                    </View>

                    <View 
                        style={{
                        borderWidth: 1,
                        opacity: 0.5,
                        borderColor: 'gray',
                        marginVertical: 12,
                        }}
                    />

                    <View
                        style={
                            {
                                paddingVertical: 8,
                                paddingHorizontal: 12,
                                alignItems: 'center',
                                width: '33%'
                            }
                        }
                    >
                        <Text style={{ color: '#BDBDBD', fontSize: 16, fontWeight: 400}}>Keluar</Text>
                        {
                            props.co_image?
                            <Image
                                style={styles.imageS}
                                source={{
                                uri: `data:image/png;base64,${props.co_image}`
                                }}
                            />
                            :
                            <View 
                            style={styles.noImage}
                            >
                            <Text style={{ fontSize: 12}}> IMAGE OUT </Text>
                            </View>
                        }
                        <Text style={{ color: bg_ok, fontSize: 18, fontWeight: 600}}>{(props.jam_pulang?date_formate_absen_jam(props.jam_pulang):'-')}</Text>
                    </View>

                    {/* <View
                        style={
                            {
                                borderWidth: 1,
                                paddingVertical: 8,
                                paddingHorizontal: 12,
                                backgroundColor: bg_ok,
                                borderColor: bg_ok,
                                borderRadius: 6,
                                alignItems: 'center',
                                width: 64,
                            }
                        }
                    >
                        <Text style={{ color: '#fff', fontSize: 16, fontWeight: 600}}>{new Date(props.id?props.id:'1997-02-07').getDate()} {date_formate_absen_hari(props.id?props.id:'1997-02-07')} </Text>
                        <Text style={{ color: '#fff', fontSize: 12, fontWeight: 600}}>{date_formate_absen_hari(props.id?props.id:'1997-02-07')}</Text>
                    </View> */}

                </View>

                <View
                    style={
                        {
                            borderWidth: 1,
                            paddingVertical: 8,
                            paddingHorizontal: 12,
                            backgroundColor: bg_ok,
                            borderColor: bg_ok,
                            borderRadius: 6,
                            alignItems: 'center',
                        }
                    }
                >
                    <Text style={{ color: '#fff', fontSize: 16, fontWeight: 600}}>{date_human_readble(props.id?props.id:'1997-02-07')} </Text>
                </View>
            </View>
        </>
    )
}



const styles = StyleSheet.create({
    

    imageS: {
        width: 88,
        height: 88,
        alignSelf: 'center',
        borderRadius: 7,
        marginTop: 8,
        marginBottom: 8,
    },

    noImage: {
        borderWidth: 1,
        borderColor: '#fff',
        borderRadius: 10,
        backgroundColor: "#fff",
        width: 88,
        height: 88,
        alignSelf: 'center',
        marginTop: 8,
        marginBottom: 8,
        justifyContent: 'center',
        alignItems: 'center'
      }
})