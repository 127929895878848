import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps';
import { Platform } from 'react-native';
import * as Location from "expo-location";
import axios from 'axios'
import LoadingScreen from './LoadingScreen';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { primaryColor } from '../Constant';
import { Button } from 'react-native-paper';
import { ApiCheck, ApiCheckIn, ApiCheckOut } from '../api/AbsenApi';
import AppModalAlert from '../components/AppModalAlert';
import { useAuth } from '../contexts/auth';

export default function Absen({route,navigation}) {
    const { photo } = route.params;
    const { signOut } = useAuth();

    var file_ba = {
        uri: photo.uri,
        type: 'image/jpg',
        name: 'Upload_absen_'+photo.uri.slice(-23),
    }
    
    const [show_alert, set_show_alert] = useState(false);
    const [text_alert, set_text_alert] = useState('');

    const [my_state, set_my_state] = useState('');
    const [loading_screen, set_loading_screen] = useState(false);
    const [region, setRegion] = useState(null);
    const [hasPermisionLocation, setHasPermisionLocation] = useState('');

    const [locationDisplayName, setLocationDisplayName] = useState('');
    const [checkedMocked, setCheckedMocked] = useState('');
    
    const [pickedLocation, setPickedLocation] = useState({
        lat: 0.0,
        lng: 0.0
    });

    const mapRegion = {
        latitude: pickedLocation.lat,
        longitude: pickedLocation.lng,
        latitudeDelta: 0.001,
        longitudeDelta: 0.001,
        // latitudeDelta: 0.0922,
        // longitudeDelta: 0.0421
    };

    useEffect( () => {
        checkState()
        getMyLocation();

        return () => {
            set_show_alert(false);
        }
    },[])


    const getMyLocation = async () => {
        set_loading_screen(true);
        let { status } = await Location.requestForegroundPermissionsAsync();

      setHasPermisionLocation( status );
  
      if (status !== 'granted') {
          setErrorMsg('Permission to access location was denied');
          alert('Permission to access location was denied')
          return false;
      }
  
      let location = await Location.getCurrentPositionAsync({});
      setPickedLocation({
          lat: location.coords.latitude,
          lng: location.coords.longitude
      });

      setCheckedMocked(location.mocked);

      getOpenMap(location.coords.latitude, location.coords.longitude);
      set_loading_screen(false);
    }

    const getOpenMap = (lati,longi, type_check) => {
      
        var config = {
          method: 'get',
          url: `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lati}&lon=${longi}`,
          headers: { }
        };
      
        axios(config)
        .then(function (response) {
            setLocationDisplayName(response.data.display_name)
            console.log("check mocked 2")
            console.log(checkedMocked);
            // console.log('response.data.display_name',response.data)
            console.log("search location")
            if (checkedMocked) {
              alert("gps f detected #2");
              return false;
            }
            
        })
        .catch(function (error) {
            alert("Check Security Location Data")
            set_loading_screen(false)
            console.log(error);
            // var mocked = text.mocked
        });
      }


    
        const checkState = async () => {
            try {
                const res = await ApiCheck();
                if ( res.data.code == '200') {
                    set_my_state(res.data.data.attendance_state)
                } else {
                    alert('Terjadi kesalahan pada server');
                    return false;
                }
                
            } catch (error) {
                if ( error == 'AxiosError: Request failed with status code 401' ) {
                    set_text_alert('Expired Token. Please try login again.');
                    set_show_alert(true);
                    setTimeout(() => {
                        signOut();
                    }, 3000);
                }
            }
        }
        const handleCheckIn = async () => {
            try {
                set_loading_screen(true);
                const res = await ApiCheckIn({
                lat: pickedLocation.lat,
                lon: pickedLocation.lng,
                mocked: (checkedMocked?'true':'false'),
                location: locationDisplayName,
                attachments: {
                        uri: photo.base64.replace('data:image/png;base64,',''),
                        type: 'image/jpeg',
                        name: 'Upload_absen_.jpg',
                    },
                    photo:photo
                })
                set_loading_screen(false);
                console.log('handleCheckIn', res)

    
                if ( res.data.code == '200') {
                    checkState();
                    // alert("Berhasil check in");
                    alert('Berhasil check in');
                    navigation.push('Attendance');
                }
            } catch (error) {
                alert('Terjadi kesalahan pada saat request');
            }

        }   

        const handleCheckOut = async () => {
            try {
                set_loading_screen(true);

                const res = await ApiCheckOut({
                    lat: pickedLocation.lat,
                    lon: pickedLocation.lng,
                    mocked: (checkedMocked?'true':'false'),
                    location: locationDisplayName,
                    attachments: {
                        uri: photo.base64.replace('data:image/png;base64,',''),
                        type: 'image/jpeg',
                        name: 'Upload_absen_.jpg',
                    },
                    photo:photo
                })
    
                set_loading_screen(false);

                console.log('handleCheckOut', res)

                if ( res.data.code == '200') {
                    set_text_alert('Berhasil check out')
                    set_show_alert(true)

                    checkState();

                    setTimeout(() => {
                        navigation.push('Attendance');

                    }, 5000);

                    // alert('Berhasil check out');
                } else {
                    alert(res.data.message);
                }
        
                
            } catch (error) {
                alert('Terjadi kesalahan pada saat request');
            }
        }

    if ( loading_screen ) {
        return (
            <LoadingScreen />
        )
    }

  return (
    <View style={{ flex: 1,}}>
        <AppModalAlert showModal={show_alert} text={text_alert} 
           
        />

        <View
            style={{
                height: '70%'
            }}
        >   
            <MapView 
                style={stylesMap.map} 

                initialRegion={mapRegion}
                provider={Platform.OS != 'web'? PROVIDER_GOOGLE:'google' }
                // provider={PROVIDER_GOOGLE}
                showsUserLocation={true}
                showsMyLocationButton={true}
                googleMapsApiKey={process.env.GOOGLE_MAPS_API_KEY}
                minZoomLevel={17}
                maxZoomLevel={19}

                scrollEnabled={false}
                // zoomEnabled={false}
                rotateEnabled={false}
                pitchEnabled={false}

                // REACTNATIVE WEB MAP
                onRegionChange={setRegion}

            >
                {
                    Platform.OS == 'web' &&
                    <Marker
                        coordinate={mapRegion}
                        title={'My Location'}
                        style={stylesMap.marker}
                    >
                        <MaterialIcons name="location-pin" color={primaryColor} size={36} />
                        
                    </Marker>
                }
            </MapView>
        </View>
        <Text style={{ fontSize: 12, textAlign: 'center', marginHorizontal: 15, marginTop: 10}}>{locationDisplayName}</Text>

        <Button 
            style={{
                marginTop: 20,
                width: '50%',
                alignSelf: 'center',
            }}
            buttonColor={primaryColor} mode='contained'
            onPress={ () => {
                navigation.navigate('ModalAbsenPhoto', {
                    photo: photo
                })
            }}
        >
                Lihat Foto
        </Button>

        <View style={{
            position: 'absolute',
            bottom: 15,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
        }}>
            {
                locationDisplayName && my_state == 'checked_out' ?
                <>
                    <Button buttonColor={primaryColor} mode='contained'
                        onPress={handleCheckIn}
                    >
                        Check In
                    </Button>
                    
                </>
                :
                <>
                    <Button buttonColor={primaryColor} mode='contained'
                        onPress={handleCheckOut}
                    >
                        Check Out
                    </Button>
                </>
            }
        </View>
    </View>
  )
}

const styles = StyleSheet.create({})


const stylesMap = StyleSheet.create({
    marker: {
      height: 48,
      width: 48
    },
    icon: {
        height: 22,
        width: 22,
        marginRight: 20,
        marginLeft: 10,
        alignSelf: 'center'
    },
    map: {
      width: '100%',
      height: '100%',
    },
  })