import { StyleSheet, Text, View } from 'react-native'
import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native'
import { dangerColor, successColor, warningColor } from '../../Constant'

export default function TicketDashboard({
  navigation
}) {

  const [data_done, set_data_done] = useState(0);
  const [data_progress, set_data_progress] = useState(0);
  const [data_cancelled, set_data_cancelled] = useState(0);

  return (
    <View
      style={{
        padding: 15,

      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 15,
        }}
      >
        <TouchableOpacity
          style={{
              backgroundColor: 'white',
              paddingHorizontal: 15,
              paddingVertical: 10,
              width: '31%',
              backgroundColor: successColor,
              borderRadius: 8,
              overflow: 'hidden',
          }}
        >
          <Text style={{ textAlign: 'center', color: 'white', fontSize: 12, marginBottom: 4,}}>Done</Text>
          <Text style={{ textAlign: 'center', color: 'white', fontSize: 16, fontWeight: '600'}}>{data_done}</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={{
              backgroundColor: 'white',
              paddingHorizontal: 15,
              paddingVertical: 10,
              width: '31%',
              backgroundColor: warningColor,
              borderRadius: 8,
              overflow: 'hidden',
          }}
        >
          <Text style={{ textAlign: 'center', color: 'white', fontSize: 12, marginBottom: 4,}}>Progess</Text>
          <Text style={{ textAlign: 'center', color: 'white', fontSize: 16, fontWeight: '600'}}>{data_progress}</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={{
              backgroundColor: 'white',
              paddingHorizontal: 15,
              paddingVertical: 10,
              width: '31%',
              backgroundColor: dangerColor,
              borderRadius: 8,
              overflow: 'hidden',
          }}
        >
          <Text style={{ textAlign: 'center', color: 'white', fontSize: 12, marginBottom: 4,}}>Cancelled</Text>
          <Text style={{ textAlign: 'center', color: 'white', fontSize: 16, fontWeight: '600'}}>{data_cancelled}</Text>
        </TouchableOpacity>

      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <TouchableOpacity
          style={{
              backgroundColor: 'white',
              paddingHorizontal: 15,
              paddingVertical: 10,
              borderRadius: 8,
          }}

          onPress={ () => {
            navigation.navigate('TicketList')
          }}
        >
          <Text>List Ticket</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({})