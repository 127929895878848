import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native'
import { ScrollView } from 'react-native'
import CardStorageItem from '../../components/storage/CardStorageItem'
import StorageFolderItem from '../../components/storage/StorageFolderItem'
import { primaryColor } from '../../Constant'
import { ProgressBar, MD3Colors } from 'react-native-paper';
import { formatFileSize } from '../../helper/GlobalFunction'

export default function StorageFolderScreen() {
    const [init_cap, set_init_cap] = useState(2147483648); // bytes 2gb
    const [capacity, set_capacity] = useState(2147483648);
    const [used_space, set_used_space] = useState(524288000);
    const [used_space_progress, set_used_space_progress] = useState(524288000);
    const [free_space, set_free_space] = useState(0);

    useEffect( () => {
        
        set_used_space_progress(used_space_progress/init_cap);
        set_free_space(parseInt(capacity)-parseInt(used_space));

        console.log('free_space', free_space)
    }, [])
  return (
    <SafeAreaView
        style={{
            flex: 1,
            backgroundColor: 'white'
        }}
    >
        <View
            style={{
                backgroundColor: 'white',
                elevation: 7,
                borderBottomEndRadius: 10,
                borderBottomStartRadius: 10,
                padding: 15,
                
                borderWidth: 1,
                borderColor: primaryColor
            }}
        >
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Text>Capacity: { formatFileSize(capacity)}</Text>
            </View>
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 10,
                }}
            >
                <Text>Used Space: { formatFileSize(used_space)}  </Text>
                <Text>Free space:  { formatFileSize(free_space)}</Text>
            </View>

            <ProgressBar progress={used_space_progress} color={primaryColor} />
        </View>
        <ScrollView
            contentContainerStyle={{
                paddingBottom: 50
            }}
        >
            <View
                style={{
                    // flexDirection: 'row',
                    justifyContent: 'space-between',
                    // flexWrap: 'wrap',
                    // gap: 10,
                }}
            >
                <StorageFolderItem 
                    folder_name="Belajar Masak"
                    folder_items={'33'}
                    folder_size={'256 mb'}
                />
                <StorageFolderItem 
                    folder_name="Belajar Berjalan"
                    folder_items={'10'}
                    folder_size={'450 mb'}
                />
                <StorageFolderItem 
                    folder_name="Belajar C sharp"
                    folder_items={'100'}
                    folder_size={'450 mb'}
                />
                <StorageFolderItem 
                    folder_name="Belajar C sharp"
                    folder_items={'100'}
                    folder_size={'450 mb'}
                />
                <StorageFolderItem 
                    folder_name="Belajar C sharp"
                    folder_items={'100'}
                    folder_size={'450 mb'}
                />
                <StorageFolderItem 
                    folder_name="Belajar C sharp"
                    folder_items={'100'}
                    folder_size={'450 mb'}
                />
                <StorageFolderItem 
                    folder_name="Belajar C sharp"
                    folder_items={'100'}
                    folder_size={'450 mb'}
                />
                <StorageFolderItem 
                    folder_name="Belajar C sharp"
                    folder_items={'100'}
                    folder_size={'450 mb'}
                />
                <StorageFolderItem 
                    folder_name="Belajar C sharp"
                    folder_items={'100'}
                    folder_size={'450 mb'}
                />
                <StorageFolderItem 
                    folder_name="Belajar C sharp"
                    folder_items={'100'}
                    folder_size={'450 mb'}
                />
                
            </View>
        </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})