import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import StorageFeedSceen from './StorageFeedScreen';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { primaryColor } from '../../Constant';
import StorageFolderScreen from './StorageFolderScreen';

const Tab = createBottomTabNavigator();

export default function StorageSceen() {
  return (
    <Tab.Navigator>
      <Tab.Screen
        name="StorageFeed"
        component={StorageFeedSceen}
        options={{
            headerShown: false,
            tabBarActiveTintColor: primaryColor,
            tabBarLabel: 'Home',
            tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="home" color={color} size={size} />
            ),
        }}
      />
      <Tab.Screen
        name="StorageFolder"
        component={StorageFolderScreen}
        options={{
            headerShown: false,
            tabBarActiveTintColor: primaryColor,
            tabBarLabel: 'Folder',
            tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="folder" color={color} size={size} />
            ),
        }}
      />
    </Tab.Navigator>
  );
}