import React from 'react';
import { View, Button, ActivityIndicator } from 'react-native';
import { Text } from 'react-native-paper';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';

const FileExportAndDownloadScreen = () => {
  const [exporting, setExporting] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);

  const data = [
    ['Name', 'Age', 'Country'],
    ['John', 25, 'USA'],
    ['Jane', 30, 'Canada'],
  ];

  const generateCsvData = () => {
    return data.map(row => row.join(',')).join('\n');
  };

  const exportCsv = async () => {
    setExporting(true);

    const csvData = generateCsvData();
    const filePath = `${FileSystem.documentDirectory}example.csv`;

    try {
      await FileSystem.writeAsStringAsync(filePath, csvData, {
        encoding: FileSystem.EncodingType.UTF8,
      });
      console.log('File exported to:', filePath);
    } catch (error) {
      console.error('Error exporting file:', error);
    } finally {
      setExporting(false);
    }
  };

  const shareCsv = async () => {
    setDownloading(true);

    const filePath = `${FileSystem.documentDirectory}example.csv`;

    try {
      const uri = `file://${filePath}`;
      await Sharing.shareAsync(uri, { UTI: 'public.comma-separated-values-text' });
      console.log('File shared:', uri);
    } catch (error) {
      console.error('Error sharing file:', error);
    } finally {
      setDownloading(false);
    }
  };

  const handleExportAndShare = async () => {
    await exportCsv();
    await shareCsv();
  };

  return (
    <>
      <Button
        title="Export and Share CSV xx"
        onPress={handleExportAndShare}
        disabled={exporting || downloading}
      />
      {exporting && <ActivityIndicator style={{ marginTop: 10 }} />}
      {downloading && <ActivityIndicator style={{ marginTop: 10 }} />}
      {(exporting || downloading) ? (
        <Text style={{ marginTop: 10 }}>Processing...</Text>
      ) : (
        <Text style={{ marginTop: 10 }}>Click the button to export and share the CSV file.</Text>
      )}
    </>
  );
};

export default FileExportAndDownloadScreen;
