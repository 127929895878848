import React from 'react';
import { View, Button, ActivityIndicator } from 'react-native';
import { Provider, Text } from 'react-native-paper';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import * as Print from 'expo-print';
import { dangerColor, successColor } from '../Constant';
// import XLSX from 'xlsx';

const FileExportPDF = ({data_x, file_name, btn_color}) => {
  const [exporting, setExporting] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);


  // const data = [
  //   ['Name', 'Age', 'Country'],
  //   ['John', 25, 'USA'],
  //   ['Jane', 30, 'Canada'],
  // ];

  const generateCsvData = () => {
  console.log('FileExportPDF data_x',data_x)

    return data_x.map(row => row.join(',')).join('\n');
  };

  const exportCsv = async () => {
    setExporting(true);

    const csvData = generateCsvData();
    const filePath = `${FileSystem.documentDirectory}${file_name}.csv`;

    try {
      await FileSystem.writeAsStringAsync(filePath, csvData, {
        encoding: FileSystem.EncodingType.UTF8,
      });
      console.log('File exported to:', filePath);
    } catch (error) {
      console.error('Error exporting file:', error);
    } finally {
      setExporting(false);
    }
  };

  const downloadCsv = async () => {
    setDownloading(true);

    const filePath = `${FileSystem.documentDirectory}${file_name}.csv`;

    try {
      const uri = `file://${filePath}`;
      await Sharing.shareAsync(uri);
      console.log('File shared:', uri);
    } catch (error) {
      console.error('Error sharing file:', error);
    } finally {
      setDownloading(false);
    }
  };

  const generatePdfContent = () => {
    // Format data_x menjadi konten HTML yang sesuai untuk PDF
    const tableRows = data_x.map(row => {
      return `<tr>${row.map(cell => `<td style="padding: 8px;">${cell}</td>`).join('')}</tr>`;
    });
  
    const tableHtml = `
      <html>
        <head>
          <style>
            /* Gaya CSS sesuai kebutuhan Anda */
          </style>
        </head>
        <body>
          <table>
            ${tableRows.join('')}
          </table>
        </body>
      </html>
    `;
  
    return tableHtml;
  };
  

 
    const handleExportAndDownload = async () => {
        setExporting(true);

        const pdfContent = generatePdfContent(); // Create a function to generate the appropriate HTML content for the PDF

        // Set your custom file name here
        const customFileName = file_name;

        const pdf = await Print.printToFileAsync({
            html: pdfContent,
            // width: 612, // Adjust the width as needed
            // height: 792, // Adjust the height as needed
            // Set the custom file name for the PDF
            fileName: customFileName,
        });

        try {
            await Sharing.shareAsync(pdf.uri);
            console.log('PDF shared:', pdf.uri);
        } catch (error) {
            console.error('Error sharing PDF:', error);
        } finally {
            setExporting(false);
        }
    };

  return (
    <>
      {/* <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}> */}
        <Button
          color={btn_color}
          title="Download PDF"
          onPress={handleExportAndDownload}
          disabled={exporting || downloading}
        />
        {exporting && <ActivityIndicator style={{ marginTop: 10 }} />}
        {downloading && <ActivityIndicator style={{ marginTop: 10 }} />}
        {(exporting || downloading) ? (
          <Text style={{ marginTop: 10 }}>Processing...</Text>
        ) : (
            <></>
        //   <Text style={{ marginTop: 10 }}>Click the button to export and download the PDF file.</Text>
        )}
      {/* </View> */}
    </>
  );
};

export default FileExportPDF;
