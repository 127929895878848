import { Dimensions, Platform, SafeAreaView, ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Image } from 'react-native'
import { primaryColor, widthDesk } from '../Constant';
import { Button } from 'react-native-paper';

const itemWidth = (Platform.OS !== 'web' ? Dimensions.get('window').width : widthDesk )* 1;
export default function Welcome({navigation}) {
  return (
    <SafeAreaView style={{ flex: 1,  backgroundColor: 'white'}}>
        <ScrollView
            horizontal
            pagingEnabled
            scrollEventThrottle={200}
            snapToInterval={itemWidth - 0}
            showsHorizontalScrollIndicator={false}
            disableIntervalMomentum={ true } 
            snapToAlignment={"center"}
            decelerationRate="fast"
            contentContainerStyle={{
                alignItems: 'center',
                // height: '80%'
            }}
        >
            <View>
                <View>
                    <Image 
                        source={ require('../assets/illustrasi/inovative.png')}
                        style={{
                            width: itemWidth,
                            height: 350,
                            resizeMode: 'contain'
                        }}
                    />
                </View>
                <View>
                    <Text style={{ color: primaryColor, textAlign: 'center', fontWeight: 'bold', fontSize: 34}}>Inovative</Text>
                </View>
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 50,
                }}>
                    <View style={{ height: 20, width: 20, borderRadius: 80, backgroundColor: primaryColor, borderWidth: 1, borderColor: primaryColor, alignSelf: 'center', marginRight: 10 }} />
                    <View style={{ height: 20, width: 20, borderRadius: 80, backgroundColor: 'white', borderWidth: 1, borderColor: primaryColor, alignSelf: 'center', marginRight: 10 }} />
                    <View style={{ height: 20, width: 20, borderRadius: 80, backgroundColor: 'white', borderWidth: 1, borderColor: primaryColor, alignSelf: 'center', marginRight: 10 }} />
                </View>
            </View>

            <View>
                <View>
                    <Image 
                        source={ require('../assets/illustrasi/team-work.png')}
                        style={{
                            width: itemWidth,
                            height: 350,
                            resizeMode: 'contain'
                        }}
                    />
                </View>
                <View>
                    <Text style={{ color: primaryColor, textAlign: 'center', fontWeight: 'bold', fontSize: 34}}>Team Work</Text>
                </View>
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 50,
                }}>
                    <View style={{ height: 20, width: 20, borderRadius: 80, backgroundColor: 'white', borderWidth: 1, borderColor: primaryColor, alignSelf: 'center', marginRight: 10 }} />
                    <View style={{ height: 20, width: 20, borderRadius: 80, backgroundColor: primaryColor, borderWidth: 1, borderColor: primaryColor, alignSelf: 'center', marginRight: 10 }} />
                    <View style={{ height: 20, width: 20, borderRadius: 80, backgroundColor: 'white', borderWidth: 1, borderColor: primaryColor, alignSelf: 'center', marginRight: 10 }} />
                </View>
            </View>

            <View>
                <View>
                    <Image 
                        source={ require('../assets/illustrasi/team-goals.png')}
                        style={{
                            width: itemWidth,
                            height: 350,
                            resizeMode: 'contain'
                        }}
                    />
                </View>
                <View>
                    <Text style={{ color: primaryColor, textAlign: 'center', fontWeight: 'bold', fontSize: 34}}>Team Goals</Text>
                </View>
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 50,
                }}>
                    <View style={{ height: 20, width: 20, borderRadius: 80, backgroundColor: 'white', borderWidth: 1, borderColor: primaryColor, alignSelf: 'center', marginRight: 10 }} />
                    <View style={{ height: 20, width: 20, borderRadius: 80, backgroundColor: 'white', borderWidth: 1, borderColor: primaryColor, alignSelf: 'center', marginRight: 10 }} />
                    <View style={{ height: 20, width: 20, borderRadius: 80, backgroundColor: primaryColor, borderWidth: 1, borderColor: primaryColor, alignSelf: 'center', marginRight: 10 }} />
                </View>
            </View>
        </ScrollView>
        <View
            style={{
                flex: 1,
            }}
        >
            <Button
                style={{
                    width: '50%',
                    alignSelf: 'center',
                }}
                buttonColor={primaryColor}
                mode='contained'
                onPress={ () => navigation.navigate('Login')}
            >
                Login
            </Button>
        </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})