import { Image, SafeAreaView, ScrollView, StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import LoadingScreen from '../LoadingScreen';
import { AuthSession_objectGetData, AuthSession_stringGetData, AuthSession_stringStoreData } from '../../helper/session/AuthSession';
import { bgColor, gray_1, primaryColor } from '../../Constant';
import { Button, TextInput } from 'react-native-paper';
import { ApiUserDetail, ApiUserUpdate } from '../../api/UserApi';

export default function ProfileEdit({}) {
    const [loading_screen, set_loading_screen] = useState(true);
    const [partnerData, set_partnerData ] = useState('')

    const [form, set_form] = useState({
      name:'',
      email:'',
      phone: '',

    })
    
    useEffect( () => {
      setTimeout(() => {
          set_loading_screen(false)
            retriveSession()
        }, 1000);

    }, [])

    const handleInputChange = async (name, value) => {
        set_form((prevFormData) => ({
          ...prevFormData,
          [name]: value
        }));
    
    };

    const retriveSession = async () => {
        set_loading_screen(true);
        const pd = await AuthSession_objectGetData('partnerData');
        const name = await AuthSession_stringGetData('name');
        const email = await AuthSession_stringGetData('email');
        const phone = await AuthSession_stringGetData('phone');

        console.log('pd', pd)

        handleInputChange('avatar_128', pd.avatar_128);
        handleInputChange('name', name);
        handleInputChange('email', email);
        handleInputChange('phone', (phone?phone:''));
        set_partnerData(pd);
        set_loading_screen(false)
    }


    const handleSave = async () => {
      set_loading_screen(true);
      const res = await ApiUserUpdate(form);
      set_loading_screen(false);

      console.log('res handleSavexxx', res.data);
      if ( res.data.code == '400' ) {
        // alert()

        // res.data.errors.( (v) => {
        //   console.log('vvv',v)
        // })
        var resultError = '';
        Object.keys( res.data.errors).map( (key) => {
          console.log('asdasdas', res.data.errors[key])
          resultError = resultError.concat(resultError,key+' '+res.data.errors[key], '\n')

          console.log('resultError',resultError)
        })

        alert(resultError)

        return false;
      }

      if ( res.data.code == '200') {

            // await AuthSession_stringStoreData('avatar_128', res.data.data.partner_details.avatar_128)
            await AuthSession_stringStoreData('name', form.name)
            await AuthSession_stringStoreData('email', form.email)
            await AuthSession_stringStoreData('phone', form.phone)
            alert('Berhasil ubah profil')

      }

    }

    if ( loading_screen ) {
        return (
            <LoadingScreen />
        )
    }
  return (
    <SafeAreaView
      style={
        styles.container
      }
    >
      <ScrollView>
        <View>
          <Image
              style={styles.profileImgHeader}
              source={{
                uri: `data:image/png;base64,${form.avatar_128}`
              }}
          />
        </View>
        <View
          style={
            styles.containerForm
          }
        >
          <TextInput
                style={{
                    marginBottom: 25,
                    
                }}
                activeUnderlineColor={primaryColor}
                label="Name"
                
                value={form.name}
                onChangeText={( text ) => {
                  handleInputChange('name',text)
                }}
          />

          <TextInput
                editable={false}
                style={{
                    marginBottom: 25,
                    
                }}
                activeUnderlineColor={primaryColor}
                label="Email"
                
                value={form.email}
                onChangeText={( text ) => {
                  handleInputChange('email',text)
                }}
          />

          <TextInput
                style={{
                    marginBottom: 25,
                    
                }}
                activeUnderlineColor={primaryColor}
                label="Phone"
                keyboardType='phone-pad'
                
                value={form.phone}
                onChangeText={( text ) => {
                  handleInputChange('phone',text)
                }}
          />
        </View>
      </ScrollView>

      <View
        style={{
          paddingHorizontal: 15,
        }}
      >
        <Button
            style={{
                width: '100%',
                alignSelf: 'center',
                marginBottom: 35,
            }} 
            // icon="camera" 
            mode="contained" 
            buttonColor={primaryColor} 
            onPress={handleSave}
        >
            SAVE
        </Button>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1,
  },
  label: {
    fontSize: 12,
    color: 'gray',
  },
  containerForm: {
    padding: 15, 
  },
  profileImgHeader: {
    width: 120,
    height: 120,
    borderRadius: 60,
    backgroundColor: gray_1,
    borderWidth: 1,
    alignSelf: 'center',
    marginTop: 15,
},
})