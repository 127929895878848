import { StyleSheet,  View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Button, Text, TextInput } from 'react-native-paper';
import { primaryColor } from '../../Constant';
import { AuthSession_stringGetData } from '../../helper/session/AuthSession';
import { parsedPhone } from '../../helper/GlobalFunction';
import axios from 'axios';

export default function Whatsapp() {

  const [form, set_form] = useState({});

  useEffect( () => {
    retriveSession();

  }, [])

  const retriveSession = async () => {
    const name = await AuthSession_stringGetData("@name");
    handleInputChange('name',name);
  }

  const handleInputChange = async (name, value) => {
    set_form((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));

  };

  const handleSubmit = async () => {
    if (!form.receiver) {
      alert("Please type receiver number");
      return false;
    }

    if ( !form.message ) {
      alert("Please type your message");
      return false;
    }


    var prepareMessage = `${form.message}  \n\nDikirim dari *Remala Group*. \nOleh : *${form.name}*`

    var data = JSON.stringify({
        "receiver": (form.receiver),
        "message": {
          "text": prepareMessage
        }
      });
      
      var config = {
        method: 'post',
        url: 'http://mapi.fibermedia.co.id:21492/chats/send?id=joni',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };


    axios(config)
    .then(function (response) {
      if ( response.data.success === true) {
        alert(response.data.message)
        setMessage('');
      } else {
        alert(response.data.message)
      }
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
      alert('Error '+error.response.status)
    });

  }

  return (
    <View style={
      styles.container
    }>
      <View
        style={ styles.formGroup }
      >
        <Text variant='labelMedium'>Receiver {form.receiver}</Text>
        <TextInput 
          keyboardType='number-pad'
          activeUnderlineColor={primaryColor}
          onChangeText={ (text) => {
            handleInputChange('receiver', parsedPhone(text))
          }}
          placeholder='Ex: 088299862277'
        />
      </View>

      <View
        style={ styles.formGroup }
      >
        <Text variant='labelMedium'>Message {form.receiver}</Text>
        <TextInput 
          style={{
            textAlignVertical: 'top'
          }}
          activeUnderlineColor={primaryColor}
          numberOfLines={5}
          onChangeText={ (text) => {
            handleInputChange('message', text)
          }}
          placeholder='Ex: Halo apakabar?'
        />
      </View>

      <View
        style={{
          flex: 1,
        }}
      >

      </View>

      <View
        style={ styles.formGroup }
      >
        <Button 
          style={{
            backgroundColor: primaryColor,
          }}
          mode='contained' 
          onPress={handleSubmit}>Submit</Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 15,
    flex: 1,
  },
  formGroup: {
    paddingHorizontal: 15,
    marginBottom: 15
  }
})