// In App.js in a new project

import React, {useEffect } from 'react';
import { View, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import MyTabs from '../screens/MyTabs';
import AbsenCamera from '../screens/AbsenCamera';
import Login from '../screens/Login';
import { useAuth } from '../contexts/auth';
import Absen from '../screens/Absen';
import { Dimensions } from 'react-native';
import ModalAbsenPhoto from '../screens/ModalAbsenPhoto';
import Attendance from '../screens/Attendance';
import Welcome from '../screens/Welcome';
import { primaryColor } from '../Constant';
import TicketCreate from '../screens/ticket/TicketCreate';
import TicketDashboard from '../screens/ticket/TicketDashboard';
import TicketList from '../screens/ticket/TicketList';
import Whatsapp from '../screens/whtasapp/Whatsapp';
import Project from '../screens/project/Project';
import ProjectList from '../screens/project/ProjectList';
import ProjectDetail from '../screens/project/ProjectDetail';
import ProjectDetail_2 from '../screens/project/ProjectDetail_2';
import Report from '../screens/attendance/Report';
import Task from '../screens/task/Task';
import About from '../screens/about-app/pages/About';
import ProfileEdit from '../screens/profile/ProfileEdit';
import TimeOff from '../screens/time-off/TimeOff';
import TimeOffAdd from '../screens/time-off/TimeOffAdd';
import TaskDetai from '../screens/task/TaskDetai';
import Payroll from '../screens/payroll/Payroll';
import PayrollDetail from '../screens/payroll/PayrollDetail';
import AnnouncementScreen from '../screens/announcement/AnnouncementScreen';
import SettingScreen from '../screens/settings/SettingScreen';
import AnnouncementDetailScreen from '../screens/announcement/AnnouncementDetailScreen';
import StorageSceen from '../screens/storage/StorageSceen';


const Stack = createNativeStackNavigator();



function MyApp() {
    const { user } = useAuth();
   
  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerStyle: {
            backgroundColor: primaryColor,
          },
          headerTintColor: '#fff'
        }}
      >
        {
            user ?
            <>
                <Stack.Screen name="Home" component={MyTabs} options={{ headerShown: false, headerShadowVisible: false,}} />
                <Stack.Screen name="Attendance" component={Attendance} options={{ headerShown: true}} />
                <Stack.Screen name="Absen" component={Absen} options={{ headerShown: true}} />
                <Stack.Screen name="AbsenCamera" component={AbsenCamera} options={{ headerShown: true}} />
                <Stack.Screen name="ModalAbsenPhoto" component={ModalAbsenPhoto} options={{ headerTitle: 'My Photo', headerShown: true, presentation:'fullScreenModal'}} />
                <Stack.Screen name="TicketList" component={TicketList} options={{ headerShown: true, headerTitle: 'Ticket List'}} />
                <Stack.Screen name="TicketDashboard" component={TicketDashboard} options={{ headerShown: true, headerTitle: 'Ticket'}} />
                <Stack.Screen name="TicketCreate" component={TicketCreate} options={{ headerShown: true, headerTitle: 'Create Ticket'}} />
                <Stack.Screen name="Whatsapp" component={Whatsapp} options={{ headerShown: true, headerTitle: 'Whatsapp'}} />
                <Stack.Screen name="Project" component={Project} options={{ headerShown: true, headerTitle: 'Project'}} />
                <Stack.Screen name="ProjectList" component={ProjectList} options={{ headerShown: true, headerTitle: 'Project List'}} />
                <Stack.Screen name="ProjectDetail" component={ProjectDetail_2} options={{ headerShown: true, headerTitle: 'Project Detail'}} />
                <Stack.Screen name="Report" component={Report} options={{ headerShown: true, headerTitle: 'Attendance Report'}} />
                <Stack.Screen name="Task" component={Task} options={{ headerShown: true, headerTitle: 'Task'}} />
                <Stack.Screen name="TaskDetail" component={TaskDetai} options={{ headerShown: true, headerTitle: 'Task Detail'}} />
                <Stack.Screen name="About" component={About} options={{ headerShown: true, headerTitle: 'About App'}} />
                <Stack.Screen name="ProfileEdit" component={ProfileEdit} options={{ headerShown: true, headerTitle: 'Edit Profil'}} />
                <Stack.Screen name="TimeOff" component={TimeOff} options={{ headerShown: true, headerTitle: 'Time Off'}} />
                <Stack.Screen name="TimeOffAdd" component={TimeOffAdd} options={{ headerShown: true, headerTitle: 'New Time Off'}} />
                <Stack.Screen name="Payroll" component={Payroll} options={{ headerShown: true, headerTitle: 'My Payroll'}} />
                <Stack.Screen name="PayrollDetail" component={PayrollDetail} options={{ headerShown: true, headerTitle: 'Detail'}} />
                <Stack.Screen name="Announcement" component={AnnouncementScreen} options={{ headerShown: true, headerTitle: 'Announcement'}} />
                <Stack.Screen name="AnnouncementDetail" component={AnnouncementDetailScreen} options={{ headerShown: true, headerTitle: 'Announcement Detail'}} />
                <Stack.Screen name="Settings" component={SettingScreen} options={{ headerShown: true, headerTitle: 'Settings'}} />
                <Stack.Screen name="Storage" component={StorageSceen} options={{ headerShown: true, headerTitle: 'Storage'}} />
            </>
            :
            <>
            <Stack.Screen name="Welcome" component={Welcome} options={{ headerShown: false}} />
            <Stack.Screen name="Login" component={Login} options={{ headerShown: false}} />
            </>

        }
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default MyApp;