import { Image, SafeAreaView, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import React, { useState } from 'react'

import { Button } from 'react-native-paper';
import { ActivityIndicator, MD2Colors } from 'react-native-paper';
import { AuthSession_objectStoreData, AuthSession_stringStoreData } from '../helper/session/AuthSession';
import { primaryColor } from '../Constant';
import { ApiLogin } from '../api/LoginApi';
import { useAuth } from '../contexts/auth';
import { StatusBar } from 'expo-status-bar';

import { TextInput as TextInputPaper } from 'react-native-paper';

export default function Login() {
    const { signIn } = useAuth();
    const [loading, set_loading] = useState(false);
     
  const [_username, set_username] = useState('');
  const [_password, set_password] = useState('');
  const [show_password, set_show_passwotd] = useState(true);

  const handleLogin = async () => {
    try {
        if ( !_username) {
            alert("Lengkapi username");
            return false;
        }
        if ( !_password) {
            alert("Lengkapi password");
            return false;
        }
        set_loading(true)
        const res = await ApiLogin({
            username: _username,
            password: _password,
        })
        set_loading(false)
         console.log('res ApiLogin',res.data)

        //  if (res.response.status == 401) {
        //     alert('Invalid Username or Password')
        //     return false;
        // } 

       if ( res.data.code == 200 ) {
            // AuthSession_stringStoreData('id', res.data.data[0].id)
            // AuthSession_stringStoreData('token', res.data.token)
            // AuthSession_stringStoreData('@name', res.data.data[0].name,'')
            // AuthSession_stringStoreData('@mobile_phone', res.data.data[0].phone,'')
            // AuthSession_stringStoreData('@login', res.data.data[0].login,'')
            // AuthSession_stringStoreData('@passwordx', _password,'')
            // AuthSession_stringStoreData('@employee_id', res.data.data[0].employee_id[0],'')
            // AuthSession_stringStoreData('@employee_name', res.data.data[0].employee_id[1],'')
            // AuthSession_stringStoreData('@company_id', res.data.data[0].company_id[0],'')
            // AuthSession_stringStoreData('@company_name', res.data.data[0].company_id[1],'')
            // AuthSession_stringStoreData('@profile_image', res.data.data[0].image_1920,'')
            // AuthSession_objectStoreData('partnerData', res.data.partnerData)

            await AuthSession_stringStoreData('id', res.data.data.id)
            await AuthSession_stringStoreData('@passwordx', _password,'')
            await AuthSession_stringStoreData('token', res.data.data.token)
            await AuthSession_stringStoreData('avatar_128', res.data.data.partner_details.avatar_128)
            await AuthSession_stringStoreData('name', res.data.data.partner_details.name)
            await AuthSession_stringStoreData('email', res.data.data.partner_details.email)
            await AuthSession_stringStoreData('phone', res.data.data.partner_details.phone)
            await AuthSession_objectStoreData('partnerData', res.data.data.partner_details)

            signIn();
       } else {
            if (res.data.code == '401' ) {
                alert('Invalid Username or Password')
            }
       }
        
    } catch (error) {
        console.log('errror',error)
       alert(error)
    }
  }

  if ( loading ) {
    return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <ActivityIndicator animating={true} color={primaryColor} />
        </View>

    )
  }

  return (
    <SafeAreaView style={{ flex: 1,}}>
        <StatusBar barStyle={'dark'} />
        <ScrollView
            style={{
                
            }}
        >
            <View
                style={{
                    // backgroundColor: primaryColor,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 300,
                }}
            >
                <Image 
                    source={ require('../assets/logo-remala-final.png')}
                    style={{
                        width: '50%',
                        height: '50%',
                    }}
                />
                <Text style={{ fontSize: 20, fontWeight: 'bold', color: 'black', marginTop: 0, }}>Remala Abadi</Text>
            </View>
            <View
                style={{
                    backgroundColor: 'white',
                    borderTopEndRadius: 50,
                    borderTopStartRadius: 50,
                    height: 600,
                }}
            >
                <View
                    style={{
                        paddingHorizontal: 25
                    }}
                >
                    <Text style={{ fontSize: 25, fontWeight: 'bold',   marginTop: 20, marginBottom: 20,}}>Login</Text>
                    <TextInputPaper
                        style={{
                            marginBottom: 25,
                        }}
                        activeUnderlineColor={primaryColor}
                        label="Username"
                        right={<TextInputPaper.Icon icon="face-man-profile" />}
                        
                        value={_username}
                        onChangeText={( text ) => {
                        set_username(text)
                        }}
                    />
                    <TextInputPaper
                        style={{
                            marginBottom: 25,
                        }}
                        activeUnderlineColor={primaryColor}
                        label="Password"
                        secureTextEntry={ show_password}
                        right={
                            <TextInputPaper.Icon icon={show_password?"eye":'eye-off'} onPress={ () => {
                                set_show_passwotd(!show_password)
                            }} />
                        }

                        value={_password}
                        onChangeText={( text ) => {
                            set_password(text)
                        }}
                    />

                    <View style={{width: undefined}}>
                        <Button
                            style={{
                                width: '100%',
                                alignSelf: 'center',
                                marginBottom: 50,
                            }} 
                            // icon="camera" 
                            mode="contained" 
                            buttonColor={primaryColor} 
                            onPress={() => {
                                handleLogin()
                            }}
                        >
                            Login
                        </Button>
                    </View>

                    <TouchableOpacity>
                        <Text>Lupa Password ?</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})