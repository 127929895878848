import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { SafeAreaView } from 'react-native'
import { ScrollView } from 'react-native'
import CardStorageItem from '../../components/storage/CardStorageItem'

export default function StorageFeedSceen() {
  return (
    <SafeAreaView
        style={{
            flex: 1,
            backgroundColor: 'gainsboro'
        }}
    >
        <ScrollView
            contentContainerStyle={{
                paddingBottom: 50
            }}
        >
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingHorizontal: 15,
                    flexWrap: 'wrap',
                    gap: 10,
                }}
            >
                <CardStorageItem 
                    title="Hello.jpg"
                />
                <CardStorageItem 
                    title="Hello.jpg"
                />
                <CardStorageItem 
                    title="Hello.jpg"
                />
                <CardStorageItem 
                    title="Hello.jpg"
                />
            </View>
        </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})