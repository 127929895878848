import { FlatList, StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { ApiGetTimeOff, ApiGetTimeOffType } from "../../api/TimeOffApi";
import { SafeAreaView } from "react-native";
import { StatusBar } from "expo-status-bar";
import { List } from "react-native-paper";
import FlatListWithRefresh from "../FlatListWithRefresh";
import { Searchbar } from 'react-native-paper';
import { ApiSpecificPayroll } from "../../api/PayrollApi";
import { FontAwesome5 } from '@expo/vector-icons';
import { primaryColor } from '../../Constant';

export default function PayrollList({
    onSelect,
    navigation
}) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const [searchQuery, setSearchQuery] = React.useState('');

    const [form, set_form] = useState({
        payroll_list: [],
        filtered_data: [],
    })

    useEffect( () => {
        getApiTimeOff();
    }, [])
    useEffect( () => {
        const filteredItems = form.payroll_list.filter(item => 
            // item.name.toLowerCase() === searchQuery.toLowerCase()
            item.display_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        handleInputChange('filtered_data',filteredItems);
    
    },[searchQuery])

    const handleInputChange = async (name, value) => {
        set_form((prevFormData) => ({
          ...prevFormData,
          [name]: value
        }));
    
    };

    const onChangeSearch = query => {
        setSearchQuery(query)
    };

    const getApiTimeOff = async (dataLempar) => {
        const res = await ApiSpecificPayroll();
        console.log('ressss x',res)

        if ( res.code == '200') {
            
                handleInputChange('payroll_list',res.data)
                handleInputChange('filtered_data',res.data)
                // setTimeout(() => {
                //     setSearchQuery(' ')
                // }, 3000);

            // handleInputChange('payroll_list',[])
        } else {
            alert(res.message)
        }

    }
    
  return (
        <>
            {/* <FlatList
                data={form.payroll_list}
                renderItem={({item}) => {
                    return (
                        <List.Item
                            style={{
                                borderBottomWidth: 1,
                                borderBottomColor: 'gainsboro'
                            }}
                            title={item.display_name}
                            titleNumberOfLines={2}
                            left={props => <List.Icon {...props} icon="calendar" />}
                        />
                    )
                }}
                keyExtractor={item => item.id}
            /> */}
            <View style={{ marginHorizontal: 17, marginTop: 15}}>
                <Searchbar
                placeholder="Search"
                onChangeText={onChangeSearch}
                value={searchQuery}
                />
            </View>
            <FlatListWithRefresh 
                data={form.filtered_data}
                fetchData={getApiTimeOff}
                renderItem={({item}) => {
                    return (
                        <List.Item
                            style={{
                                borderBottomWidth: 1,
                                borderBottomColor: 'gainsboro'
                            }}
                            title={item.display_name}
                            titleNumberOfLines={2}
                            left={props => <FontAwesome5 {...props} name="file-invoice-dollar" size={24} color={primaryColor} />}
                            onPress={ () => {
                                navigation.navigate('PayrollDetail', {
                                    item:item
                                })
                            }}
                        />
                    )
                }}
            />
        </>
  )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: StatusBar.currentHeight || 0,
    },
})