import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { SafeAreaView } from 'react-native'
import PayrollList from '../../components/payroll/PayrollList'

export default function Payroll({ route, navigation }) {
  return (
    <SafeAreaView
        style={{
            flex: 1,
        }}
    >
        <PayrollList 
            navigation={navigation}
        />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})