import {API_MID, API_TOKEN, API_URL_MOBILE, API_URL_PORTAL,
MID_URL,
MID_DB,
} from "@env"
import axios from "axios";
import { AuthSession_objectGetData, AuthSession_stringGetData } from "../helper/session/AuthSession";
// require('dotenv').config()
export const ApiGetTimeOffType = async (params) => {
    const token = await AuthSession_stringGetData('token');
    const username = await AuthSession_stringGetData('@login');
    const email = await AuthSession_stringGetData('email');
    const password = await AuthSession_stringGetData('@passwordx');

    console.log('token', token);
    try {

       var myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Content-Type", "multipart/form-data");

        console.log("mid db. ", MID_DB)


        var urlencoded = new FormData();
        urlencoded.append("url", MID_URL);
        urlencoded.append("db", MID_DB);
        urlencoded.append("username", email);
        urlencoded.append("password", password);
        urlencoded.append("model", "hr.leave.type");
        // urlencoded.append("field[name]", "");
        // urlencoded.append("field[id]", "");

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        const res = await fetch(API_MID+"/read", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log('result xx', result)
            return result;
        })
        // .catch((error,response) => console.log('error', error));


        return res;
    } catch (error) {
        console.log('ApiGetTimeOffType Error', error)
        return error.response;
        
    }
}


export const ApiGetTimeOff = async (params) => {
    const token = await AuthSession_stringGetData('token');
    const username = await AuthSession_stringGetData('@login');
    const email = await AuthSession_stringGetData('email');
    const password = await AuthSession_stringGetData('@passwordx');

    console.log('token', token);
    try {

       var myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Content-Type", "multipart/form-data");

        console.log("mid db. ", MID_DB)


        var urlencoded = new FormData();
        urlencoded.append("url", MID_URL);
        urlencoded.append("db", MID_DB);
        urlencoded.append("username", email);
        urlencoded.append("password", password);
        urlencoded.append("model", "hr.leave");
        // urlencoded.append("field[name]", "");
        // urlencoded.append("field[id]", "");

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        const res = await fetch(API_MID+"/read", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log('result api', result)
            return result;
        })
        // .catch((error,response) => console.log('error', error));


        return res;
    } catch (error) {
        console.log('ApiGetTimeOffType Error', error)
        return error.response;
        
    }
}


export const ApiSaveTimeOff = async (params) => {
    const token = await AuthSession_stringGetData('token');
    const user_id = await AuthSession_stringGetData('id');
    const username = await AuthSession_stringGetData('@login');
    const email = await AuthSession_stringGetData('email');
    const password = await AuthSession_stringGetData('@passwordx');

    console.log('token', token);
    try {

       var myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Content-Type", "multipart/form-data");

        var urlencoded = new FormData();
        urlencoded.append("url", MID_URL);
        urlencoded.append("db", MID_DB);
        urlencoded.append("username", email);
        urlencoded.append("password", password);
        urlencoded.append("model", "hr.leave");
        urlencoded.append("field[holiday_type]", "employee");
        urlencoded.append("field[employee_id]", user_id);
        urlencoded.append("field[holiday_status_id]", params.time_off_type_id);
        urlencoded.append("field[date_from]", params.start_date+' 00:00:00');
        urlencoded.append("field[date_to]", params.end_date+' 00:00:00');
        urlencoded.append("field[request_unit_half]", "");
        urlencoded.append("field[request_date_from_period]", "");
        urlencoded.append("field[name]", params.description);
        urlencoded.append("field[supported_attachment_ids]", "");
        // urlencoded.append("field[name]", "");
        // urlencoded.append("field[id]", "");

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        console.log('params create', user_id)

        const res = await fetch(API_MID+"/create", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log('result xx', result)
            return result;
        })
        // .catch((error,response) => console.log('error', error));

        return res;
    } catch (error) {
        console.log('ApiSaveTimeOff Error', error)
        return error.response;
        
    }
}