import { Image, ImageBackground, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { ActivityIndicator, MD2Colors } from 'react-native-paper';
import { primaryColor } from '../Constant';
export default function LoadingScreen() {

  const bg_color1 = 'rgba(0,0,0,0.5)';
  const bg_color2 = 'white';
    
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center', alignItems: 'center',
      }}
    >
      <Image 
        source={ require('../assets/logo-remala-final.png')}
        resizeMode='contain'
        style={{
          width: '50%',
          height: '50%',
        }}
      />
      
      <ActivityIndicator size={'large'} animating={true} color={primaryColor} />
      <Text style={{ color: primaryColor}}>Loading</Text>
    </View>
    // <ImageBackground style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: bg_color2}}
      // resizeMode='contain'
    // >
    //     <ActivityIndicator size={'large'} animating={true} color={'white'} />
    //   <Text style={{ color: 'white'}}>Loading</Text>
    // </ImageBackground>
  )

  // OLD VERSI PERTAMA KALI
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ActivityIndicator animating={true} color={primaryColor} />
      <Text>Loading</Text>
    </View>
  )
}

const styles = StyleSheet.create({})