import {API_URL, API_TOKEN, API_URL_MOBILE} from "@env"
import axios from "axios";
import { AuthSession_stringGetData } from "../helper/session/AuthSession";
// require('dotenv').config()
import { ImageManipulator, manipulateAsync, SaveFormat  } from 'expo-image-manipulator';
import { Platform } from "react-native";

function generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
  
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  
    return result;
  }

export const ApiCheckIn = async (params) => {
    const randomString = generateRandomString(10);
    
    const boundary = `--------------------------${randomString}`;
    // console.log('params.mocked',params.mocked)
    // console.log('params.attachments',params.attachments.uri)
    try {
        const token = await AuthSession_stringGetData('token');
        const username = await AuthSession_stringGetData('@login');
        const password = await AuthSession_stringGetData('@passwordx');

        const res = await axios({
            method: 'POST',
            url: API_URL_MOBILE+'/attendances/checkin',
            headers: {
                Authorization: 'Bearer '+token

                //     // Accept: 'application/json',
                //     'Content-Type': 'application/json',
                //     'Content-Type': 'multipart/form-data',
                //     // 'x-access-token': token
            },
            data: {
              username: username,
              password: password,
              lat: params.lat,
              lon: params.lon,
              mocked: params.mocked,
              location: params.location,
              attachments: params.attachments.uri,
            }

            // url: API_URL+'/api/attendaces/checkin',
            // method: 'POST',
            // headers: {

            //     // Accept: 'application/json',
            //     'Content-Type': 'application/json',
            //     'Content-Type': 'multipart/form-data',
            //     // 'x-access-token': token
            // },
            // data: formData
        });


        return res;
    } catch (error) {
        console.log('ApiCheckIn Error', error)
        return error;
        
    }
}

export const ApiCheckOut = async (params) => {
    const randomString = generateRandomString(10);
    const token = await AuthSession_stringGetData('token');
    const username = await AuthSession_stringGetData('@login');
    const password = await AuthSession_stringGetData('@passwordx');
    
    const boundary = `--------------------------${randomString}`;
    // console.log('params.mocked',params.mocked)
    // console.log('params.attachments',params.attachments.uri)
    try {
      

        const res = await axios({
            method: 'POST',
            url: API_URL_MOBILE+'/attendances/checkout',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token,

                //     'Content-Type': 'multipart/form-data',
                //     // 'x-access-token': token
            },
            data: {
              username: username,
              password: password,
              lat: params.lat,
              lon: params.lon,
              mocked: params.mocked,
              location: params.location,
              attachments: params.attachments.uri,
            }

        });


        return res;
    } catch (error) {
        console.log('Api CHECKOUT TOKEN '+token)
        console.log('Api CHECKOUT Error '+API_URL_MOBILE+'/attendances/checkout', error)
        return error;
        
    }
}


export const ApiCheck = async (params) => {
    try {
        const token = await AuthSession_stringGetData('token');
        const username = await AuthSession_stringGetData('@login');
        const password = await AuthSession_stringGetData('@passwordx');
        // const res = await axios({
        //     method: 'post',
        //     url: API_URL+'/api/attendaces/checkout',
        //     data: {
        //       username: username,
        //       password: password,
        //       lat: params.lat,
        //       lon: params.lon,
        //       mocked: params.mocked,
        //       location: params.location,
        //       attachments: params.attachments.replaceAll('"','').replace('data:image/png;base64,',''),
        //     }
        // });

        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        const res = axios({
            url    : API_URL_MOBILE+'/attendances/state',
            method : 'POST',
            headers: {
                'Authorization': 'Bearer '+token

                //     // Accept: 'application/json',
                //     'Content-Type': 'application/json',
                //     'Content-Type': 'multipart/form-data',
                //     // 'x-access-token': token
            },
            data: {
              username: username,
              password: password,
            }
        })

        return res;
    } catch (error) {
        console.log('ApiCheck Error', error)
        return error;
        
    }
}

export const ApiAttendanceReport = async (date) => {
    try {
        const token = await AuthSession_stringGetData('token');
        const username = await AuthSession_stringGetData('@login');
        const password = await AuthSession_stringGetData('@passwordx');
 

        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        const res = axios({
            url    : API_URL+'/api/mobile/attendances/reports',
            method : 'POST',
            headers: {
                'Authorization': 'Bearer '+token

                //     // Accept: 'application/json',
                //     'Content-Type': 'application/json',
                //     'Content-Type': 'multipart/form-data',
                //     // 'x-access-token': token
            },
            data: {
              username: username,
              password: password,
            //   date: "2023-06-05",
              date: date,
            }
        })


        return res;
    } catch (error) {
        console.log('ApiAttendanceReport Error', error.response)
        return error;
        
    }
}