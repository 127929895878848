import {API_URL, API_TOKEN, API_URL_MOBILE, API_URL_PORTAL} from "@env"
import axios from "axios";
import { AuthSession_objectGetData, AuthSession_stringGetData } from "../helper/session/AuthSession";


export const ApiGetProjectList = async (params) => {
    const token = await AuthSession_stringGetData('token');

    const username = await AuthSession_stringGetData('@login');
    const password = await AuthSession_stringGetData('@passwordx');

    console.log('token', token);
    try {
        const res = await axios({
            method: 'GET',
            url: API_URL_MOBILE+'/projects',
            headers: {
                'Authorization': 'Bearer '+ token,
            },
            data: {
              username: username,
              password: password,
            }
        });

        return res;
    } catch (error) {
        console.log('ApiGetProjectList Error', error)
        console.log('ApiGetProjectList end point ', API_URL_MOBILE+'/projects')
        return error.response;
        
    }
}

export const ApiGetProjectDetail = async (params) => {
    const token = await AuthSession_stringGetData('token');

    const username = await AuthSession_stringGetData('@login');
    const password = await AuthSession_stringGetData('@passwordx');

    console.log('token', token);
    try {
        const res = await axios({
            method: 'POST',
            url: API_URL_PORTAL+'/projects',
            headers: {
                'Authorization': 'Bearer '+ token,
            },
            data: {
              username: username,
              password: password,
              id: params.id,
            }
        });

        return res;
    } catch (error) {
        console.log('ApiCreateTicket Error', error)
        return error.response;
        
    }
}