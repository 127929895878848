import { FlatList, Image, SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import LoadingScreen from '../LoadingScreen';
import { ApiAttendanceReport } from '../../api/AbsenApi';
import { primaryColor } from '../../Constant';
import { date_formate_absen_jam, getMonthsAndFirstDates } from '../../helper/GlobalFunction';
import DropDownPicker from 'react-native-dropdown-picker';
import { ReportItem } from './ReportItem';
import { useAuth } from '../../contexts/auth';
import moment from "moment/moment";
import FileExportPDF from '../../components/FileExportPDF';
import { Button, Menu, Divider, Provider } from 'react-native-paper';
import FileExportAndDownloadScreen from '../../components/FileExportAndDownloadScreen';


export default function Report() {
    const { signOut } = useAuth();

    const [visible, setVisible] = React.useState(false);

    const openMenu = () => setVisible(true);
  
    const closeMenu = () => setVisible(false);
  

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const [loading_screen, set_loading_screen] = useState(false);
    const [data_report, set_data_report] = useState([]);
    const [data_report_export, set_data_report_export] = useState([
      ['Nama', 'Hari/Tanggal', 'Check IN', 'Check OUT']
    ]);
    const [ selectedId, setSelectedId ] = useState(null);

    const [openMonth, setOpenMonth] = useState(false);
    const [valueMonth, setValueMonth] = useState(null);
    const [itemsMonth, setItemsMonth] = useState([]);
    const [month_name, set_month_name] = useState('');
    
    const [openYear, setOpenYear] = useState(false);
    const [valueYear, setValueYear] = useState(null);
    const [itemsYear, setItemsYear] = useState([
      {
        value: parseInt(currentYear) - 0,
        label: parseInt(currentYear) - 0
      },
      {
        value: parseInt(currentYear) - 1,
        label: parseInt(currentYear) - 1
      },
      {
        value: parseInt(currentYear) - 2,
        label: parseInt(currentYear) - 2
      },
      
    ]);
    const [year_name, set_year_name] = useState('');


    useEffect( () => {
        // callApiAttendanceReport();
        // Contoh pemanggilan fungsi
      const monthsAndFirstDates = getMonthsAndFirstDates();
      // console.log('monthsAndFirstDates',monthsAndFirstDates);
      setItemsMonth(monthsAndFirstDates);
    }, [])

    useEffect( () => {
      if ( valueMonth ) {
        var bulan = valueMonth.substring(4)
        var tahun = valueYear;
        var result = tahun +bulan;

        if ( !valueYear ) {
          alert('Silahkan pilih tahun');
        } else {
          callApiAttendanceReport(result)
        }

      }
    },[valueMonth,valueYear])

    const callApiAttendanceReport = async (date) => {
      try {
        set_data_report([]);
        set_loading_screen(true);
        const res = await ApiAttendanceReport(date);
        set_loading_screen(false);

        var arrayExport = [
          ['Nama', 'Hari/Tanggal', 'Check IN', 'Check OUT']
        ];


        // console.log('res callApiAttendanceReport', res.data.data);
        if ( res.data.code == '200' ){

          set_data_report(res.data.data);
          // date_human_readble(v.check_in?v.check_in:'1997-02-07'), (v.check_in?date_formate_absen_jam(v.check_in):'-'), (v.check_out?date_formate_absen_jam(v.check_out):'-')

                  res.data.data.map( (v,i) => {
                    const data = [
                      v.employee_id[1]
                      ,  v.check_in?moment(v.check_in).format("dddd D MMMM YYYY"):''
                      , v.check_in?moment(v.check_in).add(7, 'hours').format('YYYY-MM-DD HH:mm:ss'):''
                      , v.check_out?moment(v.check_out).add(7, 'hours').format('YYYY-MM-DD HH:mm:ss'):'-'
                    ]
                     arrayExport.push(data)
                  })
          
                set_data_report_export(arrayExport);
                  // console.log("arrayExport data",arrayExport)
        } else {
          alert('Terjadi Kesalahan Pada saat request')
        }
        
        
      } catch (error) {
        // console.log('cacaca',error.response.status)
        if ( error.response.status == '401' ) {
          alert('Expired! Please try login again');
          signOut()
          return false;
        }
      }
        
    }

    const renderItem = ({ item }) => {
      const backgroundColor = item.id === selectedId ? 'coral' : primaryColor;
      const color = item.id === selectedId ? primaryColor : (item.late_check_in>1?'red':'black');
      const colorLate =  'red';
      return (
          // <View style={styles.containerItem}>
          //     <Item
          //       item={item}
          //       onPress={() => {
          //           setSelectedId(item.id); console.log(item.id)
          //       }}
          //     //   backgroundColor={{ backgroundColor }}
          //       textColor={{ color }}
          //       textColorLate={{ colorLate }}
          //     />
          // </View>
          <ReportItem 
            id={item.check_in}
            jam_masuk={item.check_in}
            jam_pulang={item.check_out}
            late={item.late_check_in}
            ci_image={item.ci_image}
            co_image={item.co_image}
          />
      );
  };

    if ( loading_screen ) {
        return (
            <LoadingScreen />
        )
    }

  return (
    <SafeAreaView
      style={{
        flex: 1,
      }}
    >
      <View style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
        paddingTop: 15,
      }}>
        <View style={{
          width: '45%'
        }}>
           <DropDownPicker
            placeholder='Bulan'
            listMode='MODAL'
            open={openMonth}
            value={valueMonth}
            items={itemsMonth}
            setOpen={setOpenMonth}
            setValue={setValueMonth}
            setItems={setItemsMonth}
            onSelectItem={ (item) => {
              set_month_name(item.label);
            }}
          />

        </View>

        <View style={{
          width: '45%'
        }}>
          <DropDownPicker
            placeholder='Tahun'
            listMode='MODAL'
            open={openYear}
            value={valueYear}
            items={itemsYear}
            setOpen={setOpenYear}
            setValue={setValueYear}
            setItems={setItemsYear}
            onSelectItem={ (item) => {
              set_year_name(item.label);
            }}
          />
        </View>
      </View>

        {
          data_report_export.length > 1 &&
          <>

            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
              }}>
              <Menu
                visible={visible}
                onDismiss={closeMenu}
                contentStyle={{
                  backgroundColor: primaryColor,
                }}
                
                anchor={<Button onPress={openMenu}>Export</Button>}>
                <FileExportPDF data_x={data_report_export} file_name={'Attendance Report '+month_name+' - '+ year_name } btn_color={ primaryColor}  />
                <FileExportAndDownloadScreen data_x={data_report_export} file_name={'Attendance Report '+month_name+' - '+ year_name }  btn_color={ primaryColor} />
              </Menu>
            </View>
          </>
        }
      <FlatList
            contentContainerStyle={{
              paddingBottom: 100,
            }}
            data={data_report}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            extraData={selectedId}
        />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
      flex: 1,
      // paddingTop: StatusBar.currentHeight,
  },
  containerItem: {
      padding: 10,
      margin: 10,
      paddingTop: 10,
      borderWidth: 1,
      borderColor: primaryColor,
      borderRadius: 10,
      backgroundColor: "#fff"
  },
    scrollView: {
      // backgroundColor: 'pink',
      flex: 1,
      marginHorizontal: 0,
    },
    text: {
      fontSize: 42,
    },
  
    buttonsView: {
      flex: 1,
      alignItems: "center"
    },
  
    buttonView: {
      marginTop: 20,
      marginBottom: 20,
      width: '50%',
      backgroundColor: "#9370db",
      borderRadius: 30,
      overflow: "hidden",
    },

    textDisplayName: {
      marginBottom: 10,
    },

    imageS: {
      width: 88,
      height: 88,
      alignSelf: 'center',
      borderRadius: 7,
      marginTop: 8,
      marginBottom: 8,
    },

    noImage: {
      borderWidth: 1,
      borderColor: primaryColor,
      borderRadius: 10,
      backgroundColor: "#fff",
      width: 88,
      height: 88,
      alignSelf: 'center',
      marginTop: 8,
      marginBottom: 8,
      justifyContent: 'center',
      alignItems: 'center'
    }
})