import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, IconButton } from 'react-native-paper';
import TaskListComponent from './TaskListComponent';
import LoadingScreen from '../LoadingScreen';

export default function Task({ route, navigation }) {
  const [loading_screen, set_loading_screen] = useState(true);

  useEffect( () => {
    setTimeout(() => {
      set_loading_screen(false)
    }, 1000);
  }, [])

  if ( loading_screen ) {
    return (
      <LoadingScreen />
    )
  }
  return (
    <View>
      <TaskListComponent 
        route={route}
        navigation={navigation}
      />
    </View>
  )
}

const styles = StyleSheet.create({})