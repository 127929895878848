import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { useEffect } from 'react';
import { numberWithDot } from '../../helper/GlobalFunction';

export default function PayrollDetail({
    route,
    navigation
}) {
    const { item } = route.params;

    useEffect( () => {
        console.log('item detail', item)
    }, [])
  return (
    <View>
      <Text style={{ padding: 15, fontSize: 14, fontWeight: '600'}}>{item.display_name.toUpperCase()}</Text>
        {
            item.line_ids.length < 1 &&
        <Text style={{ fontWeight: '500', textAlign: 'center', backgroundColor: 'white', padding: 15,}}>Line ids not found</Text>
        }
      {
        item.line_ids.map( (v,i) => {
            return (
                <>
                    <View
                        key={i}
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            paddingHorizontal: 15,
                            paddingVertical: 10,
                        }}
                    >
                        <Text style={{ fontWeight: '500'}}>{v.display_name}</Text>
                        <Text>{ numberWithDot(v.amount)}</Text>

                    </View>
                
                </>
            )
        })
      }
      
    </View>
  )
}

const styles = StyleSheet.create({})