import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native'
import { ScrollView } from 'react-native'
import { Pressable } from 'react-native'
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { primaryColor } from '../../Constant'
import { ApiPengumuman } from '../../api/PengumumanApi'
import LoadingScreen from '../LoadingScreen'
import AnncouncementCard from '../../components/announcement/AnncouncementCard'

export default function AnnouncementScreen({
    navigation
}) {
    const [loading, set_loading] = useState(true);
    const [data_pengumuman, set_data_pengumuman] = useState([])

    useEffect( () => {
        onLoad();
    }, [])

    const onLoad = async () => {
        set_loading(true);

        await getApiPengumuman();

        set_loading(false);

    } 

    const getApiPengumuman = async () => {
        try {
            const res = await ApiPengumuman();
            console.log('res pengj', res.data.data);
            set_data_pengumuman(res.data.data)
            
        } catch (error) {
            alert('error: '+ error);
        }
    } 

    if ( loading ) {
        return (
            <LoadingScreen />
        )
    }
  return (
    <SafeAreaView 
        style={{
            flex: 1,
        }}
    >
        <Pressable
            style={{
                backgroundColor: primaryColor,
                width: 50,
                height: 50,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 100,
                position: 'absolute',
                bottom: 30,
                zIndex: 100,
                right: 15,
            }}
        >
            <FontAwesome name="plus" size={24} color="white" />
        </Pressable>
        <ScrollView
            contentContainerStyle={{
                paddingBottom: 15,
            }}
        >
            {
                data_pengumuman.length > 0 ? 
                data_pengumuman.map( (v, i) => {
                    console.log('test',v.company_id[1]);
                    return (
                        <AnncouncementCard
                            key={i}
                            item={v}
                            onPress={ () => {
                                navigation.push('AnnouncementDetail', {
                                    item: v,
                                })
                            }}
                        />
                    )
                    
                })
                :
                <></>
            }
        </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})