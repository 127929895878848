import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import { Dimensions } from 'react-native'
import { Image } from 'react-native';

export default function CardStorageItem({
    title
}) {

    const { width } = Dimensions.get('screen');
    const cust_width = width / 1 - 30

    const viewRef = useRef(null);
    const [viewHeight, setViewHeight] = useState(0);

    useEffect(() => {
        // Access the height in viewHeight after the component is mounted
        console.log('View Height: ', viewHeight);
    }, []);

    const handleLayout = (event) => {
        const { width } = event.nativeEvent.layout;
    
        // Calculate the height based on the width and a desired ratio
        const height = width * 0.5; // You can adjust the ratio as needed
        setViewHeight(height);
    };
  return (
    <View
        ref={viewRef} onLayout={handleLayout}
        style={{
            backgroundColor: 'white',
            width: cust_width,
            borderRadius: 8,
            marginTop: 15,
            overflow: 'hidden',
            padding: 10,
        }}
    >
      <Text style={{ padding: 5}}>{title}</Text>
      <View
        style={{
        }}
      >
        <Image source={{ uri: "https://rbasset.s3.ap-southeast-1.amazonaws.com/2021/04/kawasan-Summarecon-Bekasi.jpg"}}  style={{
            height: viewHeight,
            resizeMode: 'cover',
            borderRadius: 15,
        }}/>

        <View
            style={{
                flexDirection: "row",
                columnGap: 5,
                marginTop: 10,
                alignItems: 'center',
            }}
        >
            <Image source={{ uri: "https://t3.ftcdn.net/jpg/00/42/19/70/360_F_42197066_aigiZf1P60VUB9YKfrttLq4GxCa1iYT9.jpg"}}  style={{
                height: 30,
                width: 30,
                resizeMode: 'cover',
                borderRadius: 100,
            }}/>
            <Text style={{ marginTop: 0, fontWeight: '700'}}>Rinaldy</Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({})