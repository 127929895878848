import { Dimensions, Platform } from 'react-native'
import {API_URL_MBL, API_URL_WEB} from "@env"
import Constants from 'expo-constants';
const { width } = Dimensions.get('window');


if ( Platform.OS == 'web' ) {
    var VMAPI = API_URL_WEB;
} else {
    var VMAPI = API_URL_MBL;
}

export const widthDesk = 420;
export const MAPI = VMAPI;
export const MAPI2 = API_URL_MBL;

// export const primaryColor = "#9370db";
export const bgColor = "rgba(12, 41, 120, 0.03)";
export const primaryColor = "#0C2978";
export const successColor = '#5cb85c';
export const warningColor = '#f0ad4e';
export const dangerColor = '#d9534f';
export const gray_1 = "#f8f8f8";

export const secondaryColor = "grey";
export const version = Constants.expoConfig.version;
export const appContainer = {
    flex: 1,
    padding: 16,
}
export const appLabel = {
    fontSize: 12,
    color: 'grey'
};

export const textArea =  {
    marginTop: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: primaryColor,
    textAlignVertical: 'top'
}

export const fwBold =  {fontWeight: 'bold'};
export const textGroup =  {
    marginBottom: 10, 
    padding: 5
};

export const AppWidth = width>425?425:'100%';

export const errorServer = 'Terjadi Kesalahan Pada Server';
export const errorInvalidToken = 'Invalid Token. Silahkan login ulang.';


export const ImageStatic = {
    "data_not_found": require('./assets/images/data-not-found.png') 
}

// FUNCTION 
export const convertTgl = (tgl) => {
    // return tgl.toLocaleDateString()
    return tgl.toISOString().split('T')[0];
}

export const convertWaktu = (tgl) => {
    return tgl.toLocaleTimeString()
}