import React, { useEffect, useState } from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { primaryColor } from '../Constant';

const AppModalConfirmation = ({ isVisible, message, onCancel, onConfirm, cancel_text, confirm_text }) => {
   
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onRequestClose={onCancel}
    >
      <View style={styles.container}>
        <View style={styles.modal}>
          <Text style={styles.message}>{message}</Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity onPress={onCancel}>
              <Text style={styles.cancelButton}>{cancel_text}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={onConfirm}>
              <Text style={styles.confirmButton}>{confirm_text}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modal: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 8,
  },
  message: {
    fontSize: 18,
    marginBottom: 20,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    fontSize: 16,
    color: primaryColor,
    marginRight: 10,
    padding: 5,
    paddingHorizontal: 20,
    borderRadius: 5,
    backgroundColor: 'white',
    borderColor: primaryColor,
    borderWidth: 1,
  },
  confirmButton: {
    fontSize: 16,
    color: 'white',
    padding: 5,
    paddingHorizontal: 20,
    borderRadius: 5,
    backgroundColor: primaryColor,
  },
});

export default AppModalConfirmation;
