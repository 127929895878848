import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, Modal, StyleSheet } from 'react-native';
import { primaryColor } from '../Constant';

const AppModalLogout = ({ visible, onHandleLogout, onOpenModal}) => {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect( () => {
    console.log('change truexx', visible)
    // setModalVisible(!visible)
  },[visible])

  const handleLogout = () => {
    // Tambahkan kode di sini untuk menghandle logout
    // Misalnya, panggil fungsi untuk logout dari aplikasi atau hapus token autentikasi
    // setelah itu, tutup modal
    if ( onHandleLogout ) {
        onHandleLogout();
    }
    closeModal();
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <View style={styles.container}>
      {/* <TouchableOpacity onPress={openModal} style={styles.button}>
        <Text style={styles.buttonText}>Logout</Text>
      </TouchableOpacity> */}

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={closeModal}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalText}>Apakah Anda yakin ingin logout?</Text>
            <View style={styles.buttonContainer}>
              <TouchableOpacity onPress={closeModal} style={[styles.button, styles.cancelButton]}>
                <Text style={styles.buttonText}>Batal</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={handleLogout} style={[styles.button, styles.logoutButton]}>
                <Text style={styles.buttonText}>Logout</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default AppModalLogout;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
  button: {
    backgroundColor: '#3498db',
    padding: 10,
    borderRadius: 5,
    marginVertical: 10,
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
  },
  modalText: {
    fontSize: 18,
    marginBottom: 15,
  },
  buttonContainer: {
    flexDirection: 'row',
    gap: 10,
  },
  logoutButton: {
    backgroundColor: primaryColor,
    width: 100,
    alignItems: 'center',
  },
  cancelButton: {
    backgroundColor: '#ccc',
    width: 100,
    alignItems: 'center',
  },
});
