import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Button } from 'react-native-paper'
import { primaryColor } from '../../Constant'

export default function Project({
    navigation
}) {
  return (
    <View style={ styles.container }>
        <Button mode="contained" buttonColor={primaryColor} onPress={() => {
            navigation.navigate('ProjectList')
        }}>
            Project List
        </Button>
    </View>
  )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 15,
    }
})