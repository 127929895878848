import { Dimensions, Image, StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { ApiTask } from '../../api/TaskApi'
import { AppWidth, ImageStatic, primaryColor } from '../../Constant';
import { ActivityIndicator } from 'react-native';
import { FlatList } from 'react-native';
import { Avatar, Button, Card, IconButton } from 'react-native-paper';
import LoadingScreen from '../LoadingScreen';
import FlatListWithRefresh from '../../components/FlatListWithRefresh';
import { SafeAreaView } from 'react-native';

export default function TaskListComponent({ route, navigation}) {
    const {width: windowWidth, height: windowHeight} = Dimensions.get('window');

    const [loading_screen, set_loading_screen] = useState(true);

    const [form, set_form] = useState({
        tasks: [],
        is_refreshing: false,

    })

    useEffect( () => {
        getApiTask();

    }, [])

    const handleInputChange = async (name, value) => {
        set_form((prevFormData) => ({
          ...prevFormData,
          [name]: value
        }));
    
    };

    const getApiTask = async () => {
        const res = await ApiTask();
        set_loading_screen(false);
        handleInputChange('is_refreshing', false);
        if ( res.data.code == '200') {
            handleInputChange('tasks', res.data.data );

        } else {
            // alert(res.data.status)
            if ( res.data.errors?.tasks) {
                alert(res.data.errors?.tasks);
            } else {
                alert(res.data.message);
            }
        }

        console.log('debug getApiTask', res.data);
    }

    const onRefresh = () => {
        handleInputChange('is_refreshing',true)
        getApiTask();
    }

    const handleOnPress = async (item) => {
        navigation.navigate('TaskDetail', {
            id: item.id,
            name: item.name
        })
    }

    // if ( loading_screen ) {
    //     return (
    //         // <LoadingScreen />
    //     )
    // }

  return (
    <SafeAreaView
        style={{
            marginTop: 15, 
        }}
    >
        {
            form.tasks.length > 0 ?
            (
                <FlatListWithRefresh 
                  data={form.tasks}
                  fetchData={getApiTask}
                  renderItem={ ({item}) => {
                      return (
                          <Card
                              onPress={ () => {
                                  handleOnPress(item)
                              }}
                              style={{
                                  marginBottom: 15,
                                  marginHorizontal: 15
                              }}
                          >
                              <Card.Title
                                  title={item.name}
                                  subtitle={item.create_date}
                                  left={(props) => <Avatar.Icon {...props} icon="folder" style={{ backgroundColor: primaryColor}} />}
                              />
                            
                          </Card>
                      )
                  }}
                
                
                />
            )

            :

            (
                <View style={{
                    height: windowHeight-200,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Image 
                        source={ ImageStatic.data_not_found}
                        style={{
                            height: windowHeight ,
                            width: AppWidth ,
                            resizeMode: 'contain',
                        }}
                    />
                </View>
            )
        }
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})