import {API_MID, API_TOKEN, API_URL_MOBILE, API_URL_PORTAL,
MID_URL,
MID_DB,
} from "@env"
import axios from "axios";
import { AuthSession_objectGetData, AuthSession_stringGetData } from "../helper/session/AuthSession";
// require('dotenv').config()

export const ApiSpecificPayroll = async (params) => {
    const token = await AuthSession_stringGetData('token');
    const user_id = await AuthSession_stringGetData('id');
    // const username = await AuthSession_stringGetData('@login');
    const email = await AuthSession_stringGetData('email');
    const password = await AuthSession_stringGetData('@passwordx');

    console.log('token', token);
    try {

        var myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Content-Type", "multipart/form-data");

        var urlencoded = new FormData();
        // urlencoded.append("url", "http://172.16.35.34:8072");
        // urlencoded.append("db", "erpnew");
        // urlencoded.append("username", "admin");
        // urlencoded.append("password", "Justbeyourself");
        urlencoded.append("url", MID_URL);
        urlencoded.append("db", MID_DB);
        urlencoded.append("username", email);
        urlencoded.append("password", password);

        urlencoded.append("model", "hr.payslip");
        urlencoded.append("domain[employee_id.id]", user_id);
        urlencoded.append("field[date_from]", "");
        urlencoded.append("field[date_to]", "");
        urlencoded.append("field[employee_id]", "");
        urlencoded.append("field[struct_id]", "");
        urlencoded.append("field[company_id]", "");
        urlencoded.append("field[journal_id]", "");
        urlencoded.append("field[contract_id]", "");
        urlencoded.append("field[name]", "");
        urlencoded.append("field[state]", "");
        urlencoded.append("field[line_ids]", "");
        urlencoded.append("relation[line_ids]", "hr.payslip.line");
        urlencoded.append("field[worked_days_line_ids]", "");
        urlencoded.append("relation[worked_days_line_ids]", "hr.payslip.worked_days");
        urlencoded.append("field[input_line_ids]", "");
        urlencoded.append("relation[input_line_ids]", "hr.payslip.input");

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        const res = await fetch(API_MID+"/read", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log('result api', result)
            return result;
        })


        return res;
    } catch (error) {
        console.log('ApiSpecificPayroll Error', error)
        return error.response;
        
    }
}
