import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import ExampleExportCSV from '../../../components/ExampleExportCSV'
import FileDownloadScreen from '../../../components/FileDownloadScreen'

export default function About() {
  return (
    <View>
      <Text>About</Text>
      <ExampleExportCSV />
      <FileExportAndDownloadScreen />
      <FileDownloadScreen />
    </View>
  )
}

const styles = StyleSheet.create({})