import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import MyApp from './myapp/MyApp';
import { AuthProvider } from './contexts/auth';
import { Dimensions } from 'react-native';
import { widthDesk } from './Constant';
import { Provider } from 'react-native-paper';

export default function App() {
  const { width } = Dimensions.get('window');

  return (
    <>
      <StatusBar style="light" />
        <AuthProvider>
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                // borderWidth: 0.2,
                borderColor: 'white',
                elevation: 7,
                width: (width>425?425:'100%'),
                  height: '100%', 
                  alignSelf: 'center',
              }}
            >
              <Provider>
                <MyApp />
              </Provider>
            </View>
          </View>
        </AuthProvider>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
