import { SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { ApiGetProjectDetail } from '../../api/ProjectApi';
import { errorInvalidToken, primaryColor } from '../../Constant';
import { Appbar } from 'react-native-paper';
import LoadingScreen from '../LoadingScreen';

const ListTask = () => {

}

export default function ProjectDetail({
    route, navigation,
}) {
    
    const [active, set_active] = useState('Tasks')
    const [loading_screen, set_loading_screen] = useState(false);

    const [data_task, set_data_task] = useState([]);
    const [data_tickets, set_data_tickets] = useState([]);
    const [count, set_count] = useState({
        task: 0,
        ticket: 0,
    })

    useEffect( () => {
        navigation.setOptions({
            headerTitle: route.params.item.name
        })
        getDetail()
    }, [])

    const getDetail = async () => {
        set_loading_screen(true);
        const res = await ApiGetProjectDetail(route.params);
        set_loading_screen(false);

        console.log('debug  getDetail', res.data.data[0]);
        if ( res.data.code == 200 ) {
            set_data_task(res.data.data[0].task_data);
            set_data_tickets(res.data.data[0].ticket_data);
            set_count({
                task: res.data.data[0].task_data.length,
                ticket: res.data.data[0].ticket_data.length,
            })
        } else if ( res.data.code == 401 ) {
            alert(errorInvalidToken);
        } else if ( res.data.code == 400 ) {
            alert('Terjadi Kesalahan Saat Request');
        } else {
            alert(errorServer);
        }
        
    }

    if ( loading_screen ) {
        return (
            <LoadingScreen />
        )
    }
  return (
    <SafeAreaView>
        <View style={
            {
                flexDirection: 'row',
                justifyContent: 'space-between'
            }
        }>
            <TouchableOpacity style={{ width: '50%', padding: 8, backgroundColor: (active == 'Tasks'? primaryColor : '')}} 
                onPress={ () => {
                    set_active('Tasks')
                }}
            >
                <Text style={{ textAlign: 'center', color: (active == 'Tasks'? 'white' : '')}}>Tasks ( { count.task} )</Text> 
            </TouchableOpacity>
            <TouchableOpacity style={{ width: '50%', padding: 8, backgroundColor: (active == 'Tickets'? primaryColor : '')}} 
                onPress={ () => {
                    set_active('Tickets')
                }}
            >
                <Text style={{ textAlign: 'center', color: (active == 'Tickets'? 'white' : '')}}>Tickets ( { count.ticket } )</Text> 
            </TouchableOpacity>

        </View>
        <ScrollView
            contentContainerStyle={{
                paddingTop: 10,
            }}
        >
            {
                active == 'Tasks' ? 
                data_task.map( (v,i) => {
                    return (
                        <View style={{ padding: 8, borderWidth: 0.5, borderRadius: 8, marginHorizontal: 15, marginBottom: 10 }}>
                            <Text style={{ color: primaryColor}}>{v.name}</Text>
                        </View>
                    )
                })
                :
                data_tickets.map( (v,i) => {
                    return (
                        <View style={{ padding: 8, borderWidth: 0.5, borderRadius: 8, marginHorizontal: 15, marginBottom: 10 }}>
                            <Text style={{ color: primaryColor}}>{v.name}</Text>
                        </View>
                    )
                })
            }
        </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})