import { FlatList, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { ApiGetProjectList } from '../../api/ProjectApi';
import { errorServer, primaryColor } from '../../Constant';
import LoadingScreen from '../LoadingScreen';
import { Searchbar } from 'react-native-paper';

export default function ProjectList({
    navigation,
}) {
    const [loading_screen, set_loading_screen] = useState(false);
    const [data_projects, set_data_projects] = useState([]);

    const [filtered_data, set_filtered_data] = useState([]);
    

    const [searchQuery, setSearchQuery] = React.useState('');

    const onChangeSearch = query => {
        console.log('qeur',query)
        setSearchQuery(query)
    };

    useEffect( () => {
        var filteredItems = []
        filteredItems = data_projects.filter(item => 
            // item.name.toLowerCase() === searchQuery.toLowerCase()
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        // BY COMMPANY
        if ( filteredItems.length < 1 ) {
            console.log('BY COMPANY')
            filteredItems = data_projects.filter(item => 
                // item.name.toLowerCase() === searchQuery.toLowerCase()
                item.company_id[1].toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        set_filtered_data(filteredItems);

    },[searchQuery])

    useEffect( () => {
        getProjects();
    }, []);

    const getProjects = async () => {
        set_loading_screen(true);
        const res = await ApiGetProjectList();
        console.log('debug getProjects', res.data);
        set_loading_screen(false);

        if ( res.data.code == '200' ) {
            set_data_projects(res.data.data)
            // set_filtered_data(res.data.data);
        } else {
            if ( res.data.errors?.projects) {
                alert(res.data.errors?.projects);
            }
        }
    }

    if ( loading_screen ) {
        return (
            <LoadingScreen />
        )
    }
  return (
    <View>
        <View style={{ marginHorizontal: 17, marginTop: 15}}>
            <Searchbar
                placeholder="Search"
                onChangeText={onChangeSearch}
                value={searchQuery}
            />
        </View>
        <FlatList
        
            contentContainerStyle={{
                paddingTop: 30,
                paddingBottom: 77,
            }}
            data={data_projects}
            renderItem={({item}) => {
                console.log('data_projects item', item)
                return (
                    <TouchableOpacity 
                        style={{  marginHorizontal: 15, marginBottom: 15, borderWidth: 0.2, borderColor: 'gray', borderRadius:8, backgroundColor: 'white',
                            elevation: 5,
                        }}
                    
                        onPress={ () => {
                            navigation.navigate('ProjectDetail', {
                                id: item.id,
                                item: item
                            })
                        } }
                    >
                        <View
                            style={{
                                backgroundColor: primaryColor,
                                paddingVertical: 2,
                                overflow: 'hidden',
                                borderTopEndRadius: 8,
                                borderTopStartRadius: 8,
                            }}
                        >
                            <Text style={{ textAlign: 'center', color: 'white', fontWeight: 'bold', fontSize: 0,}}>{}</Text>
                        </View>
                        <View
                            style={{
                                padding: 15
                            }}
                        >
                            <View style={{ marginBottom: 10}}>
                                <Text style={{ fontWeight: '600', fontSize: 20}}>{item.name}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                {/* <View style={{ marginBottom: 10}}>
                                    <Text style={{ fontWeight: '300', fontSize: 14,}}>Company</Text>
                                    <Text style={{ fontWeight: '600', fontSize: 14}}>{item.company_id[1]}</Text>
                                </View> */}
                                <View style={{ marginBottom: 10}}>
                                    {
                                        item.ticket_count > 0 ?
                                        (
                                            <>
                                                <Text style={{ fontWeight: '600', fontSize: 14, color: 'gray'}}>{item.task_count} Tasks</Text>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <Text style={{ fontWeight: '600', fontSize: 14, color: 'gray'}}>No task available</Text>
                                            </>
                                        )
                                    }
                                </View>

                                <View style={{ marginBottom: 10}}>
                                    {
                                        item.ticket_count > 0 ?
                                        (
                                            <>
                                                <Text style={{ fontWeight: '600', fontSize: 14, color: 'gray'}}>{item.ticket_count} Tickets</Text>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <Text style={{ fontWeight: '600', fontSize: 14, color: 'gray'}}>No ticket available</Text>
                                            </>
                                        )
                                    }
                                </View>
                            </View>
                        </View>
                    </TouchableOpacity>
                )
            }}
            keyExtractor={item => item.id}
        />
    </View>
  )
}

const styles = StyleSheet.create({})