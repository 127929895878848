import React, { useState, useEffect  } from 'react';
import { ScrollView, Text, View, useWindowDimensions, } from 'react-native';
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import { ApiGetProjectDetail } from '../../api/ProjectApi';
import LoadingScreen from '../LoadingScreen';
import { errorServer, primaryColor } from '../../Constant';
import { Searchbar } from 'react-native-paper';

const TaskRoute = ({route}) => {

    // const [active, set_active] = useState('Tasks')
    const [loading_screen, set_loading_screen] = useState(false);

    const [data_task, set_data_task] = useState([]);
    const [filtered_data, set_filtered_data] = useState(data_task);
    

    const [searchQuery, setSearchQuery] = React.useState('');

    useEffect( () => {
            console.log('task route', route.params)
            getDetail();
    }, [])

    const onChangeSearch = query => {
        console.log('qeur',query)
        setSearchQuery(query)
    };

    useEffect( () => {
        const filteredItems = data_task.filter(item => 
            // item.name.toLowerCase() === searchQuery.toLowerCase()
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        set_filtered_data(filteredItems);

    },[searchQuery])

   

    const getDetail = async () => {
        set_loading_screen(true);
        const res = await ApiGetProjectDetail(route.params);

        if ( res.data.code == 200 ) {
            set_data_task(res.data.data[0].task_data);
            set_filtered_data(res.data.data[0].task_data);
        } else if ( res.data.code == 401 ) {
            alert(errorInvalidToken);
        } else if ( res.data.code == 400 ) {
            alert('Terjadi Kesalahan Saat Request');
        } else {
            alert(errorServer);
        }
        set_loading_screen(false);
    }

    if ( loading_screen ) {
        return (
            <LoadingScreen />
        )
    }

    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <View style={{ marginHorizontal: 17, marginTop: 15}}>
                <Searchbar
                placeholder="Search"
                onChangeText={onChangeSearch}
                value={searchQuery}
                />
            </View>
            <ScrollView
                contentContainerStyle={{
                    paddingVertical: 10,
                }}
            >
                {
                    filtered_data.map( (v,i) => {
                        return (
                            <View key={i} style={{ padding: 8, borderWidth: 0.5, borderRadius: 8, marginHorizontal: 15, marginBottom: 10 }}>
                                <Text style={{ color: primaryColor}}>{v.name}</Text>
                            </View>
                        )
                    })
                }
            </ScrollView>

        </View>
    )
};


const TicketRoute = ({route}) => {

    // const [active, set_active] = useState('Tasks')
    const [loading_screen, set_loading_screen] = useState(false);

    const [data_ticket, set_data_ticket] = useState([]);
    const [filtered_data, set_filtered_data] = useState(data_ticket);
    

    const [searchQuery, setSearchQuery] = React.useState('');

    useEffect( () => {
            console.log('task route', route.params)
            getDetail();
    }, [])

    const onChangeSearch = query => {
        console.log('qeur',query)
        setSearchQuery(query)
    };

    useEffect( () => {
        const filteredItems = data_ticket.filter(item => 
            // item.name.toLowerCase() === searchQuery.toLowerCase()
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        set_filtered_data(filteredItems);

    },[searchQuery])

   

    const getDetail = async () => {
        set_loading_screen(true);
        const res = await ApiGetProjectDetail(route.params);

        if ( res.data.code == 200 ) {
            set_data_ticket(res.data.data[0].ticket_data);
            set_filtered_data(res.data.data[0].ticket_data);
        } else if ( res.data.code == 401 ) {
            alert(errorInvalidToken);
        } else if ( res.data.code == 400 ) {
            alert('Terjadi Kesalahan Saat Request');
        } else {
            alert(errorServer);
        }
        set_loading_screen(false);
    }

    if ( loading_screen ) {
        return (
            <LoadingScreen />
        )
    }

    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <View style={{ marginHorizontal: 17, marginTop: 15}}>
                <Searchbar
                placeholder="Search"
                onChangeText={onChangeSearch}
                value={searchQuery}
                />
            </View>
            <ScrollView
                contentContainerStyle={{
                    paddingVertical: 10,
                }}
            >
                {
                    filtered_data.map( (v,i) => {
                        return (
                            <View key={i} style={{ padding: 8, borderWidth: 0.5, borderRadius: 8, marginHorizontal: 15, marginBottom: 10 }}>
                                <Text style={{ color: primaryColor}}>{v.name}</Text>
                            </View>
                        )
                    })
                }
            </ScrollView>

        </View>
    )
};

// const renderScene = SceneMap({
//   Task: TaskRoute,
//   Ticket: TicketRoute,
// });


const renderScene = ({ route }) => {
    console.log('render screne', route)

    switch (route.key) {
        case "Task":
        return <TaskRoute route={route}  />;
        case "Ticket":
        return <TicketRoute route={route} />;
        default:
        <TaskRoute route={route} />;
    } 
};
const renderTabBar = props => (
    <TabBar
        {...props}
        indicatorStyle={{ backgroundColor: 'white' }}
        style={{ backgroundColor: primaryColor }}
    />
);

export default function ProjectDetail_2({route,navigation}) {
  const layout = useWindowDimensions();
  


  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'Task', title: 'Task ', params: route.params },
    { key: 'Ticket', title: 'Tickets ', params: route.params },
  ]);

  return (
    <>
        <TabView
            lazy={true}
            renderTabBar={renderTabBar}
            navigationState={{ index, routes }}
            renderScene={renderScene}
            onIndexChange={setIndex}
            initialLayout={{ width: layout.width }}
        />
    </>
  );
}